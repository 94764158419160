import React, { useState, useEffect } from 'react';
import { Offcanvas, Button } from 'react-bootstrap';

const OffCanvas = ({ onSaveEdit, show, onClose }) => {
  const [editedValues, setEditedValues] = useState({ CF: 0, CG: 0, ES: 0, SC: 0 });

  const handleSaveEdit = () => {
    onSaveEdit(editedValues);
    setEditedValues({ CF: 0, CG: 0, ES: 0, SC: 0 });
    onClose();
  };

  const handleInputChange = (field, value) => {
    setEditedValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {show && <div className="offcanvas-backdrop fade show" onClick={handleClose}></div>}
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Inserir Valores</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className="form-group grid-item" style={{ marginTop: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <h4>CF:</h4>
              <input
                type="number"
                className="form-control"
                id="formCF"
                value={editedValues.CF}
                style={{ marginLeft: '1rem', width: '30%' }}
                onChange={(e) => handleInputChange('CF', e.target.value)}
              />
            </div>
            <div className="form-group grid-item" style={{ marginTop: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <h4>CG:</h4>
              <input
                type="number"
                className="form-control"
                id="formCG"
                value={editedValues.CG}
                style={{ marginLeft: '1rem', width: '30%' }}
                onChange={(e) => handleInputChange('CG', e.target.value)}
              />
            </div>
            <div className="form-group grid-item" style={{ marginTop: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <h4>ES:</h4>
              <input
                type="number"
                className="form-control"
                id="formES"
                value={editedValues.ES}
                style={{ marginLeft: '1rem', width: '30%' }}
                onChange={(e) => handleInputChange('ES', e.target.value)}
              />
            </div>
            <div className="form-group grid-item" style={{ marginTop: '1rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <h4>SC:</h4>
              <input
                type="number"
                className="form-control"
                id="formSC"
                value={editedValues.SC}
                style={{ marginLeft: '1rem', width: '30%' }}
                onChange={(e) => handleInputChange('SC', e.target.value)}
              />
            </div>
          </form>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <Button variant="primary" onClick={handleSaveEdit} style={{ marginRight: '1rem' }}>
              Salvar
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default OffCanvas;
