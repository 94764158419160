import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import axios from "axios";

const Aba5 = ({rowData, setRowData}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idLanc = searchParams.get('idlanc');
  //const [rowData, setRowData] = useState([]);
  const [centers, setCenters] = useState([]);
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const gridRef = useRef();

  useEffect(() => {
    handleGetCenterCoust();
    if (idLanc != null){
        clearRow();
        handleGetCenterCoustId();
  }
  }, [idLanc]);

  const clearRow = () => {
    setRowData([]);
  };

  const handleGetCenterCoust = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/center_counts`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data.content)
        setCenters(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCenterCoustId = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/lanc/fat_center_coust_id?idFat=${idLanc}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data.content);
        setRowData(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddRow = () => {
    const newRow = {
      id_center: centers.length > 0 ? centers[0].id_centro : "",
      value: "0",
    };
    setRowData((prevData) => [...prevData, newRow]);
    setTimeout(() => {
      gridRef.current.api.startEditingCell({
        rowIndex: rowData.length,
        colKey: 'id_center'
      });
    }, 0);
  };

  const handleSelectChange = (index, newValue) => {
    setRowData((prevData) => {
      const newData = [...prevData];
      newData[index].id_center = newValue;
      return newData;
    });
  };

  const formatCurrency = (value) => {
    // Converter o valor para uma string
    const stringValue = value.toString();
    
    // Remover caracteres não numéricos da string
    const numericValue = parseFloat(stringValue.replace(/\D/g, ''));
    
    // Formatar o valor numérico como moeda
    return (numericValue / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };



  const getCentroNameById = (id) => {
    // Encontra o centro de custo pelo ID
    const centro = centers.find((c) => c.id_centro === id);
    return centro ? centro.centro_custos : ''; // Retorna o nome do centro
  };

  const colDefs = [
    {
      field: 'id_center',
      headerName: 'Centro de Custo',
      cellEditor: 'agSelectCellEditor',
      width: 140,
      cellEditorParams: {
        values: centers.map((center) => center.id_centro), // IDs para seleção
      },
      valueFormatter: (params) => { return getCentroNameById(params.value); },
      editable: true
    },
    {
      field: "value",
      headerName: "Valor",
      filter: true,
      width: 150,
      valueFormatter: params => formatCurrency(params.value),
      editable: true
    },
  ];

  const onCellValueChanged = (params) => {
    if (params.column.colId === 'id_center') {
      handleSelectChange(params.node.rowIndex, params.newValue);
    }
  };

  return (
    <div className="box-center1">
      <div className="card col-md-12 mx-auto">
        <div className="ag-theme-quartz" style={{ height: 600 }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={colDefs}
            rowSelection="unique"
            onCellValueChanged={onCellValueChanged}
          />
        </div>
        <div><hr className="divider" /></div>
        <div className="card-body">
          <button type="submit" className="btn btn-outline-primary" onClick={handleAddRow}>Adicionar Linha</button>
        </div>
      </div>
      {/*<div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" className="btn btn-primary" onClick={handleSave}>
          Salvar
        </button>
      </div>*/}
    </div>
  );
};

export default Aba5;
