import { Fragment } from 'react';
import { BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import Home from '../pages/home/home';
import CargoCadastro from '../pages/cargos/cargo_cadastro';
import CargoLista from '../pages/cargos/cargos_lista';
import FuncionarioCadastro from '../pages/funcionario/funcionario_cadastro';
import FuncionarioLista from '../pages/funcionario/funcionario_lista';
import {AuthProvider, useAuth } from '../context/AuthContext';
import Login from '../pages/login/login';
import CadEntidade from '../pages/cadastro/cad_entidade';
import EntConsulta from '../pages/cadastro/cad_entidade_consulta';
import CadAgendamento from '../pages/cadastro/cad_agendamento';
import CadDevolucao from '../pages/cadastro/cad_devolucao';
import ConsultaAgend from '../pages/cadastro/consul_agendamento';
import ConsultaDevol from '../pages/cadastro/consul_devolucao';
import Faturamento from '../pages/faturamento/faturamento';
import OffCanvas from '../pages/cadastro/offcanvas';
import Unauthorized from '../pages/permissoes/unauthorized';
import SessionTimeout from './session_timeout'; // Importa o componente de expiração de sessão
import Dashboards from '../pages/cadastro/dashboard';
import Dashboards2 from '../pages/cadastro/dashboard2';
import UsuariosAntomar from '../pages/cadastro/usuarios_Antom';

const Private = ({ Item, requiredPermissions }) => {
  const { permissions, loaded } = useAuth();
  const token = localStorage.getItem('token');

  if (!loaded) {
    return <div>Loading...</div>;
  }

  if (!token) {
    return <Navigate to="/login" />;
  }

  const hasPermission = requiredPermissions.some(permission => permissions[permission]);

  if (!hasPermission) {
    return <Navigate to="/unauthorized" />;
  }

  return <Item />;
};
  
  const RouteApp = () => {
    return (
      <AuthProvider>
      <BrowserRouter>
        <SessionTimeout>
        <Fragment>
          <Routes>
            {/* GERAL */}
            <Route path="*" element={<Private Item={Home} requiredPermissions={['admin','gGeral','cliente','gate','documental','agendamento','supDoc','avaria','supAva','vistoria','conferente','oficina','gerencPatio','dev']} />} />
            <Route path="/login" element={<Login/>} />
            <Route path="/home" element={<Private Item={Home} requiredPermissions={['admin','gGeral','cliente','gate','documental','agendamento','supDoc','avaria','supAva','vistoria','conferente','oficina','gerencPatio','dev']} />} />
            <Route path="/unauthorized" element={<Unauthorized/>}/>
            {/* CADASTROS */}
            <Route path="/cadastro_agendamento" element={<Private Item={CadAgendamento} requiredPermissions={['admin','gGeral','cliente','gate','documental','agendamento','supDoc','avaria','supAva','vistoria','conferente','oficina','gerencPatio','dev']} />}/>
            <Route path="/cadastro_entidade" element={<Private Item={CadEntidade} requiredPermissions={['admin','gGeral','dev','cliente']} />} />
            <Route path="/cadastro_devolucao" element={<Private Item={CadDevolucao} requiredPermissions={['admin','gGeral','cliente','gate','documental','agendamento','supDoc','avaria','supAva','vistoria','conferente','oficina','gerencPatio','dev']} />}/>
            <Route path="/cargo_cadastro" element={<Private Item={CargoCadastro} requiredPermissions={['admin','gGeral','dev']}  />} />
            <Route path="/funcionario_cadastro" element={<Private Item={FuncionarioCadastro} requiredPermissions={['admin','gGeral','dev']}  />} />
            <Route path="/permissoes_cadastro" element={<Private Item={useAuth} requiredPermissions={['admin','gGeral','dev']}  />} />
            {/* CONSULTAS */}
            <Route path="/cargo_lista" element={<Private Item={CargoLista} requiredPermissions={['admin','gGeral','dev']}  />} />
            <Route path="/funcionario_lista" element={<Private Item={FuncionarioLista} requiredPermissions={['admin','gGeral','dev']}  />} />
            <Route path="/consulta_entidade"element={<Private Item={EntConsulta} requiredPermissions={['admin','gGeral','cliente','gate','documental','agendamento','supDoc','avaria','supAva','vistoria','conferente','oficina','gerencPatio','dev']}  />} />
            <Route path="/consulta_agendamento" element={<Private Item={ConsultaAgend} requiredPermissions={['admin','gGeral','cliente','gate','documental','agendamento','supDoc','avaria','supAva','vistoria','conferente','oficina','gerencPatio','dev']} />}/>
            <Route path="/consulta_devolucao" element={<Private Item={ConsultaDevol} requiredPermissions={['admin','gGeral','cliente','gate','documental','agendamento','supDoc','avaria','supAva','vistoria','conferente','oficina','gerencPatio','dev']} />}/>
            {/* OUTROS */}
            <Route path="/faturamento" element={<Private Item={Faturamento} requiredPermissions={['admin','gGeral','dev']}  />} />
            <Route path="/offcanvas" element={<Private Item={OffCanvas} requiredPermissions={['admin','gGeral','cliente','gate','documental','agendamento','supDoc','avaria','supAva','vistoria','conferente','oficina','gerencPatio','dev']} />}/>
            {/* DASHBOARDS */}
            <Route path="/dashboard" element={<Private Item={Dashboards} requiredPermissions={['admin','gGeral','dev']}  />} />
            <Route path="/dashboard2" element={<Private Item={Dashboards2} requiredPermissions={['admin','gGeral','dev']}  />} />
            {/* USUARIOS SISTEMA ANTOMAR */}
            <Route path="/usuarios_ant" element={<Private Item={UsuariosAntomar} requiredPermissions={['admin','gGeral','dev']}  />} />
          </Routes>
        </Fragment>
        </SessionTimeout>
      </BrowserRouter>
      </AuthProvider>
    );
  };
  
  export default RouteApp;

 //'admin','gGeral','cliente','gate','documental','agendamento','supDoc','avaria','supAva','vistoria','conferente','oficina','gerencPatio','dev'
 // admin,gGeral,cliente,gate,documental,agendamento,supDoc,avaria,supAva,vistoria,conferente,oficina,gerencPatio,dev
 // permissions.admin || permissions.gGeral || permissions.cliente || permissions.gate || permissions.documental || permissions.agendamento || permissions.supDoc || permissions.avaria || permissions.supAva || permissions.vistoria || permissions.conferente || permissions.oficina || permissions.gerencPatio || permissions.dev