import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { AgGridReact } from 'ag-grid-react';

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Button } from '@mui/material';
import "../../style/style.css";
import Navbar from "../navbar/navbar";
import FaturamentoModal from "./faturamento_modal";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import SearchIcon from '@mui/icons-material/Search';

{/*import { AG_GRID_LOCALE_BR } from '@ag-grid-community/locale';*/}

const Faturamento = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idLanc = searchParams.get('idlanc');
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const gridRef = useRef();
  const today = new Date().toLocaleDateString('pt-BR'); 

  const getConsultFat = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/lanc/ListLanc/NotCancel`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        setData(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getConsultFat();
  }, []);

  useEffect(() => {
    if (idLanc !== null) {
      const modalElement = document.getElementById('modal2');
      modalElement.classList.add('show');
      modalElement.style.display = 'block';
      modalElement.setAttribute('aria-modal', 'true');
      modalElement.setAttribute('role', 'dialog');
      document.body.classList.add('modal-open');
      const backdropElement = document.createElement('div');
      backdropElement.classList.add('modal-backdrop', 'fade', 'show');
      document.body.appendChild(backdropElement);
    }
  }, [idLanc]);

  const handleVerCNTRClick = () => {
    getConsultFat();
  };
  var filterParams = {
    
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0]),
      );
  
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
  
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
  
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
    maxValidYear: 2024,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };
  const colDefs = [
    { 
      field: "lanc_os",
      headerName: "Lançamento", 
      filter: true,
      width: 150 ,
      cellStyle: (params) => ({
        backgroundColor: params.data.status === 'PAGO' ? 'green' : params.data.data_vencimento === today ? 'yellow' : params.data.data_vencimento < today ? 'red' : 'inherit',
      })
    },
    { 
      field: "data_lanc", 
      headerName: "Data",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      width: 105 ,
      cellStyle: (params) => ({
        backgroundColor: params.data.status === 'PAGO' ? 'green' : params.data.data_vencimento === today ? 'yellow' : params.data.data_vencimento < today ? 'red' : 'inherit',
      })
    },
    { 
      field: "emp", 
      headerName: "Empresa", 
      width: 150 ,
      cellStyle: (params) => ({
        backgroundColor: params.data.status === 'PAGO' ? 'green' : params.data.data_vencimento === today ? 'yellow' : params.data.data_vencimento < today ? 'red' : 'inherit',
      })
    },
    { 
      field: "client", 
      headerName: "Cliente", 
      filter: true,
      width: 150 ,
      cellStyle: (params) => ({
        backgroundColor: params.data.status === 'PAGO' ? 'green' : params.data.data_vencimento === today ? 'yellow' : params.data.data_vencimento < today ? 'red' : 'inherit',
      })
    },
    { 
      field: "valor", 
      headerName: "Valor", 
      width: 150 ,
      cellStyle: (params) => ({
        backgroundColor: params.data.status === 'PAGO' ? 'green' : params.data.data_vencimento === today ? 'yellow' : params.data.data_vencimento < today ? 'red' : 'inherit',
      })
    },
    { 
      field: "data_data_emissao_nf", 
      headerName: "Emissão", 
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      width: 150 ,
      cellStyle: (params) => ({
        backgroundColor: params.data.status === 'PAGO' ? 'green' : params.data.data_vencimento === today ? 'yellow' : params.data.data_vencimento < today ? 'red' : 'inherit',
      })
    },
    { 
      field: "data_vencimento", 
      headerName: "Vencimento", 
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      width: 150 ,
      cellStyle: (params) => ({
        backgroundColor: params.data.status === 'PAGO' ? 'green' : params.data.data_vencimento === today ? 'yellow' : params.data.data_vencimento < today ? 'red' : 'inherit',
      })
    },
    { 
      field: "status", 
      headerName: "Status", 
      filter: true,
      width: 150 ,
      cellStyle: (params) => ({
        backgroundColor: params.data.status === 'PAGO' ? 'green' : params.data.data_vencimento === today ? 'yellow' : params.data.data_vencimento < today ? 'red' : 'inherit',
      })
    },
    { 
      field: "number_nf", 
      headerName: "Nota Fiscal", 
      filter: true,
      width: 150 ,
      cellStyle: (params) => ({
        backgroundColor: params.data.status === 'PAGO' ? 'green' : params.data.data_vencimento === today ? 'yellow' : params.data.data_vencimento < today ? 'red' : 'inherit',
      })
    },
    { 
      field: "nameUser", 
      headerName: "Usuario", 
      filter: true,
      width: 150 ,
      cellStyle: (params) => ({
        backgroundColor: params.data.status === 'PAGO' ? 'green' : params.data.data_vencimento === today ? 'yellow' : params.data.data_vencimento < today ? 'red' : 'inherit',
      })
    },
    {
      field: "id_lanc",
      headerName: "Consultar",
      width: 150,
      cellRenderer: (params) => (
        <Link to={`/faturamento?idlanc=${params.data.id_lanc}`}
            className="btn btn-outline-primary btn-sm" onClick={() => handleVerCNTRClick()}>
          <SearchIcon />
        </Link>
      ),
      cellStyle: (params) => ({
        backgroundColor: params.data.status === 'PAGO' ? 'green' : params.data.data_vencimento === today ? 'yellow' : params.data.data_vencimento < today ? 'red' : 'inherit',
      })
    },
  ];

  const getCurrentDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}_${month}_${year}__${hours}_${minutes}`;
  };

  const exportToExcel = () => {
    const rowData = [];
    gridRef.current.api.forEachNodeAfterFilter((node) => {
      rowData.push(node.data);
    });

    const columns = {
      lanc_os: "Lançamento",
      data_lanc: "Data",
      emp: "Empresa",
      client: "Cliente",
      valor: "Valor",
      data_data_emissao_nf: "Emissão",
      data_vencimento: "Vencimento",
      status: "Status"
    };


    const formattedData = rowData.map(row => {
      const formattedRow = {};
      Object.keys(row).forEach(key => {
        formattedRow[columns[key] || key] = row[key];
      });
      return formattedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: Object.values(columns) });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Faturamento");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, `faturamento_${getCurrentDate()}.xlsx`);
  };


  return (
    <>
      <Navbar />
      <div className="row align-items-start g-1">
        <div className="col-12">
          <div className="box-center">
            <div className="card col-md-12 mx-auto">
              <div className="card-body pt-2">
                <form className="row g-3 py-2">
                  <legend className="form-label">Tela de Faturamento</legend>
                  <div className="container">
                    <div className="box-center1 bg-custom">
                      <div className="card col-md-12 mx-auto">
                        <div className="ag-theme-quartz" style={{ height: 600 }}>
                          <AgGridReact
                            ref={gridRef}
                            rowData={data}
                            columnDefs={colDefs}
                            rowSelection="unique"
                            pagination={true}
                          />
                        </div>
                      </div>
                      <div><hr className="divider" /></div>
                      <div className="col-12 ">
                        <Link type="button" to={`/faturamento`} className="btn mx-2 btn-primary" data-bs-target="#modal2" data-bs-toggle="modal">Adicionar</Link>
                        <button type="button" className="btn mx-2 btn-success" onClick={exportToExcel}>Exportar Excel</button>
                        {/* <button type="reset" className="btn mx-2 btn-danger">Exportar PDF</button> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FaturamentoModal />
    </>
  );
};

export default Faturamento;
