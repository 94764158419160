import React, { useState }  from "react";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../style/style.css";
import Navbar from "../navbar/navbar";
import background from "../../assets/login1.png";
import logo from "../../assets/logo.png";
import axios from 'axios';

const Login = () => {
  const [cpf_user, setCpf_user] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  const parsedURL = localStorage.getItem('urlParsed');

  const handleLogin = async (event) => {
    event.preventDefault();
    console.log(parsedURL)
    try {      
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
        const resposta = await axios.post(
            `${parsedURL}post/login`,
            {
                cpf: cpf_user,
                password: password
                
            },
            { headers }
        );
        const data = resposta.data.user_info;
        const token = resposta.data.token;
        console.log(data);
        if (data.active === 'S') {
          const currentTime = Date.now()
            localStorage.setItem('token', token);
            localStorage.setItem('typeUser',data.id_tipo_usuario);
            localStorage.setItem('idUser',data.id_usuario);
            localStorage.setItem('userName',data.nome);
            setLoading(false);
            localStorage.setItem('user_login_time',currentTime.toString());
            window.location.href = `${window.location.origin}/home`
        } else {

            toast.error('Usuario Bloqueado', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        }
    } catch (error) {
      console.log(error);
        toast.error('Usuario ou senha incorreto!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    }
};

const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
      handleLogin(event); 
  }
};

  return (
    <body className="my-login-page">
      <section className="h-100" style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
        <div className="container h-100">
          <div className="row justify-content-md-center h-100">
            <div className="card-wrapper">
              <div className="brand" style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={logo} style={{ width: '200%' }}/>
              </div>
              <div className="card fat card-fat" >
                <div className="card-body">
                  <h5 className="card-title text-center">Login</h5>
                  <div onKeyDown={handleKeyDown}>
                    <div className="form-floating mb-3">
                      <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" onChange={(e) => setCpf_user(e.target.value)} value={cpf_user} />
                      <label htmlFor="floatingInput">Usuário</label>
                    </div>
                    <div className="form-floating">
                      <input type="password" className="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} />
                      <label htmlFor="floatingPassword">Senha</label>
                    </div>
                    <div className="form-group text-center mb-3"/>
                    <div className="form-group text-center mb-3">
                      <Link to="/home"><button type="button" className="btn btn-outline-primary btnn" onClick={handleLogin}>Entrar</button></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer">
                Copyright &copy; 2023 &mdash; Transtec World
              </div>
            </div>
          </div>
          <ToastContainer
                        position="bottom-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
        </div>

      </section>
    </body>
  );
};

export default Login;
