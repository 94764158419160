import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Search from "@mui/icons-material/Search.js";
import axios from "axios";
import "../../style/style.css"

const Aba1 = ({fat, setFat}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idLanc = searchParams.get('idlanc');
  const userName = localStorage.getItem("userName");
  const idUser = localStorage.getItem("idUser");
  const idUsuarioString = String(idUser);
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const [empresas, setEmpresas] = useState([]);
  const [centers, setCenters] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [formsPag, setFormsPag] = useState([]);
  const [entidade, setEntidade] = useState([]);
  const [data, setData] = useState(getDataAtual());
  const [planCounts, setPlanCounts] = useState([]);
  const [statusRec, setStatusRec] = useState([]);
  const [editor,setEditor] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectType,setSelectType] = useState();

  useEffect(() => {
    handleGetEmpresas();
    handleGetCenterCoust();
    handleGetBanks();
    handleGetFormsPags();
    //handleGetEntidade();
    handleGetPlanConts();
    handleGetStatusRe();
    getFechaAtual();
    if (idLanc != null){
      clearFat();
      setEditor(true);
      handleGetDataFat();
      console.log (idLanc);
    
    }
  }, [idLanc]);

  const clearFat = () => {
    setFat({
      lanc_os: "",
      id_emp: "",
      fechamento: "",
      id__entidade: "",
      cliente:"",
      id_entidade_pag: "",
      cliente_pag:"",
      data_emissao_nf: "",
      data_vencimento: "",
      data_vencimentoOrig: "",
      valor: "",
      valor_receb: "",
      valor_pagm: "",
      id_bank: "",
      id_forma_pag: "",
      number_nf:"",
      boleto: "",
      comment: "",
      id_conta: "",
      id_status: "",
      id_usuario: "",
      user:"",
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    
    let formattedValue = value;
    
    // Format the value as Brazilian reais (R$) if it's a numeric field
    if (name === 'valor' || name === 'valor_receb' || name === 'valor_pagm') {
      // Remove non-digit characters
      const numericValue = parseFloat(value.replace(/\D/g, ''));
      
      // Format the value in Brazilian reais (R$)
      formattedValue = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(numericValue / 100);
    }
  
    setFat(prevState => ({
      ...prevState,
      [name]: formattedValue
    }));
  };

  function getDataAtual() {
    const hoje = new Date();
    const ano = hoje.getFullYear();
    const mes = String(hoje.getMonth() + 1).padStart(2, '0'); // Mês é base zero
    const dia = String(hoje.getDate()).padStart(2, '0');
    return `${ano}-${mes}-${dia}`;
  };

  function getFechaAtual() {
    const hoje = new Date();
    const ano = hoje.getFullYear();
    const mes = String(hoje.getMonth() + 1).padStart(2, '0'); // Mês é base zero
    const formattedDate = `${mes}/${ano}`;
    setFat(prevState => ({ ...prevState, fechamento: formattedDate }));
    console.log(formattedDate)
    return formattedDate;
  
  };

  const handleGetEntidade = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/cliente`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setEntidade(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetPlanConts = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/plan_counts`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setPlanCounts(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetFormsPags = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/forms_pag`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setFormsPag(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetBanks = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/data_banks`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setBancos(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleGetCenterCoust = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/center_counts`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setCenters(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDataFat= async () => {
    try { 
      const response = await axios.get(
        `${parsedURL}get/lanc/fat_id?idlanc=${idLanc}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        const data = response.data.content;
        console.log(response.data.content);
  
        // Format values and dates
        const formattedData = {
          ...data,
          valor: formatCurrency(data.valor),
          valor_receb: formatCurrency(data.valor_receb),
          valor_pagm: formatCurrency(data.valor_pagm),
          data_emissao_nf: formatDate(data.data_emissao_nf),
          data_vencimento: formatDate(data.data_vencimento),
          data_vencimentoOrig: formatDate(data.data_vencimentoOrig)
        };
  
        console.log(JSON.stringify(formattedData));
        setFat(formattedData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const formatCurrency = (value) => {
    if (typeof value === 'number') {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(value);
    } else {
      return value; // Retorna o valor sem formatação se não for um número
    }
  };
  
  const formatDate = (dateString) => {
    if (!dateString) return null; // Retorna null se a data for undefined ou null
  
    const [day, month, year] = dateString.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  const handleGetStatusRe = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/status_rec`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setStatusRec(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetEmpresas = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/data_empresas`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setEmpresas(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRowDoubleClick = (params) => {
    if(selectType === 'cliente'){
    setFat(prevState => ({
      ...prevState,
      id__entidade: params.data.id_entidade,
      cliente: params.data.razao_social
    }));
  }
  else{
    setFat(prevState => ({
      ...prevState,
      id_entidade_pag: params.data.id_entidade,
      cliente_pag: params.data.razao_social
    }));
  }
  setShowConfirmationModal(false);
  };

  const handleClickSeach = (tipoBusca) => {
    setSelectType(tipoBusca);
    handleGetEntidade();
    setShowConfirmationModal(true);
  };

  const columns = [
    { 
      field: "id_entidade", 
      headerName: "ID", 
      width: 80 
    },
    { 
      field: "razao_social", 
      headerName: "Razão Social", 
      width: 200,
      filter: true
    },
    { 
      field: "fantasia", 
      headerName: "Fantasia", 
      width: 200,
      filter: true 
    },
    { 
      field: "cnpj_cpf", 
      headerName: "CPF/CNPJ", 
      width: 180,
      filter: true,
    },
    //{ field: "Tipo", headerName: "Tipo", width: 150 },
    { 
      field: "cidade", 
      headerName: "Cidade", 
      width: 200,
      filter: true 
    },
    { 
      field: "estado", 
      headerName: "UF", 
      width: 80,
      filter: true
    }
  ];

  return (
    <>
      <div className="box-center1">
        <div className="card col-md-12 mx-auto">
          <div className="card-body">
            <form class="row g-3">
            <div class="row-md-1 align-items-end "></div>
              <div class="row align-items-end ">
              <div class="col-md-3">
                <label class="form-label">Lançamento</label>
                <input type="text"  readOnly={true} value={fat.lanc_os}class="form-control transparent-input" />
              </div>
              <div class="col-md-2">
                <label class="form-label" name="">Data</label>
                <input
                  type="date"
                  readOnly={true}
                  name="date"
                  className="form-control transparent-input"
                  value={data}
                  onChange={(e) => setData(e.target.value)}
                />
              </div>
              
              <div className="col-md-4">
                <label htmlFor="inputState" className="form-label">Empr.</label>
                <select id="inputState" disabled={editor} className="form-select transparent-input" value={fat.id_emp} name="id_emp" onChange={handleChange}>
                  <option value="">Selecione a Empr.</option>
                  {empresas.map((opcao) => (
                    <option key={opcao.id_emp} value={opcao.id_emp}>{opcao.fantasia}</option>
                    ))}
                </select>
              </div>
              <div class="col-md-3">
                <label for="razaosocial" class="form-label ">Fechamento C.</label>
                <input type="text" class="form-control transparent-input" 
                maxLength={7} value={fat.fechamento} name="fechamento" onChange={handleChange} />
              </div>
              </div>
              <div class="row-md-1 align-items-end "></div>
              <div class="row align-items-end ">
              <div class="col-md-4">
                  <label class="hfx form-label">Cliente</label>
                  <div class="input-group">
                    <input type="text" value={fat.cliente} readOnly={true} class="form-control transparent-input" />
                    <button type="button" onClick={() => handleClickSeach("cliente")} disabled={editor} class="input-group-text btn-primary btn"><Search /></button>
                  </div>
              </div>
                <div class="col-md-4">
                  <label class="hfx form-label">Cliente Pagador</label>
                  <div class="input-group">
                    <input type="text" value={fat.cliente_pag} readOnly={true} class="form-control transparent-input" />
                    <button type="button" onClick={() => handleClickSeach("cliente_pag")} class="input-group-text btn-primary btn"><Search /></button>
                  </div>
              </div>
              <div class="col-md-1 justify-content-center">
                <button class="btn btn-success" disabled={true}>Cadastrar</button>
              </div>
              </div>
              <div class="row-md-1 align-items-end "></div>
              <div class="row align-items-end ">
              <div class="col-md-3">
                <label for="numdocumento"  class="form-label">Emissão NF</label>
                <input type="date" readOnly={editor} class="form-control transparent-input" value={fat.data_emissao_nf} name="data_emissao_nf" onChange={handleChange}/>
              </div>
              <div class="col-md-3">
                <label for="numdocumento" class="form-label">Vencimento</label>
                <input type="date" class="form-control transparent-input" value={fat.data_vencimento} name="data_vencimento" onChange={handleChange}/>
              </div>
              <div class="col-md-3">
                <label for="razaosocial" class="form-label ">Venc. Original</label>
                <input type="date" readOnly={true} class="form-control transparent-input" value={fat.data_vencimentoOrig} name="data_vencimentoOrig" onChange={handleChange}/>
              </div>
              </div>
              <div class="col-md-3">
                <label for="razaosocial"  class="form-label ">Valor Orig.</label>
                <input type="text" placeholder="R$ 0,00" readOnly={editor} class="form-control transparent-input" value={fat.valor} name="valor" onChange={handleChange}/>
              </div>
              <div class="col-md-3">
                <label for="razaosocial" class="form-label ">Valor a Receb.</label>
                <input type="text" placeholder="R$ 0,00" class="form-control transparent-input" value={fat.valor_receb} name="valor_receb" onChange={handleChange}/>
              </div>
              <div class="col-md-3">
                <label for="razaosocial" class="form-label ">Valor Pago</label>
                <input type="text" placeholder="R$ 0,00" class="form-control transparent-input" value={fat.valor_pagm} name="valor_pagm" onChange={handleChange}/>
              </div>
              <div class="row-md-1 align-items-end "></div>
              <div class="row align-items-end ">
              <div class="col-md-3">
                <label for="inputState" class="form-label ">Banco</label>
                <select id="inputState" class="form-select transparent-input" value={fat.id_bank} name="id_bank" onChange={handleChange}>
                  <option selected value=''>Selecione o banco</option>
                  {bancos.map((opcao) => (
                    <option key={opcao.id_banco} value={opcao.id_banco}>{opcao.banco}</option>
                  ))}
                </select>
              </div>
              <div class="col-md-3">
                <label for="inputState" class="form-label ">Forma</label>
                <select id="inputState" class="form-select transparent-input" value={fat.id_forma_pag} name="id_forma_pag" onChange={handleChange}>
                  <option selected value=''>Selecione o tipo</option>
                  {formsPag.map((opcao) => (
                    <option key={opcao.id_pag} value={opcao.id_pag}>{opcao.pag_forma}</option>
                  ))}
                </select>
              </div>
              <div class="col-md-6">
                <label for="razaosocial" class="form-label ">Boleto</label>
                <input type="text" class="form-control transparent-input" value={fat.boleto} name="boleto" onChange={handleChange}/>
              </div>
              </div>
              <div class="col-md-4">
                <label for="inputState"  class="form-label ">Conta</label>
                <select id="inputState" disabled={editor} class="form-select transparent-input" value={fat.id_conta} name="id_conta" onChange={handleChange}>
                  <option selected value=''>Selecione a Conta</option>
                  {planCounts.map((opcao) => (
                    <option key={opcao.id_plan} value={opcao.id_plan}>{opcao.cont+" - "+opcao.descript}</option>
                  ))}
                </select>
              </div>
              <div class="col-md-4">
                <label for="razaosocial" class="form-label ">NF</label>
                <input type="text" class="form-control transparent-input" value={fat.number_nf} name="number_nf" onChange={handleChange}/>
              </div>
              <div class="col-md-12">
                <label for="exampleFormControlTextarea1"  class="form-label">Descrição</label>
                <textarea class="form-control transparent-input" readOnly={editor} rows="5" name="comment" value={fat.comment} onChange={handleChange}></textarea>
              </div>
              <div class="col-md-4">
                <label for="inputState" class="form-label ">Status</label>
                <select id="inputState" class="form-select transparent-input" value={fat.id_status} name="id_status" onChange={handleChange}>
                  <option selected value=''>Selecione</option>
                  {statusRec.map((opcao) => (
                    <option key={opcao.id_status} value={opcao.id_status}>{opcao.status}</option>
                  ))}
                </select>
              </div>
              <div class="col-md-4">
                <label for="razaosocial" class="form-label ">Usuario</label>
                <input type="text" value={fat.nome != "" ? fat.nome : userName} readOnly={true} class="form-control transparent-input" />
              </div>
            </form>
          </div>
        </div>
        {/*<div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" class="btn btn-primary" onClick={idLanc != null ? handleUpdate : handleSubmit}>Salvar</button>
            </div>*/}
      </div>
      <div className={`modal ${showConfirmationModal ? 'd-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: showConfirmationModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-warning">
              <h5 className="modal-title"><b>Selecione o Cliente</b></h5>
              <button type="button" className="close bg-danger text-white" aria-label="Close" onClick={() => setShowConfirmationModal(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ">
            <div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
                  <AgGridReact
                    rowData={entidade}
                    columnDefs={columns}
                    rowSelection="single"
                    onRowDoubleClicked={handleRowDoubleClick}
                  />
                </div>
            </div>
            <div className="modal-footer ">
              <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmationModal(false)}>Fechar</button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false} 
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
    </>
  );
};

export default Aba1;