import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext";
import logo from "../../assets/logo.png";
import "../../style/style.css"

const Navbar = () => {
	const navigate = useNavigate();
	const { permissions } = useAuth();

	document.addEventListener("DOMContentLoaded", function () {


		document.querySelectorAll('.dropdown-menu').forEach(function (element) {
			element.addEventListener('click', function (e) {
				e.stopPropagation();
			});
		})
		if (window.innerWidth < 992) {

			document.querySelectorAll('.navbar .dropdown').forEach(function (everydropdown) {
				everydropdown.addEventListener('hidden.bs.dropdown', function () {
					this.querySelectorAll('.submenu').forEach(function (everysubmenu) {
						everysubmenu.style.display = 'none';
					});
				})
			});

			document.querySelectorAll('.dropdown-menu a').forEach(function (element) {
				element.addEventListener('click', function (e) {

					let nextEl = this.nextElementSibling;
					if (nextEl && nextEl.classList.contains('submenu')) {
						e.preventDefault();
						console.log(nextEl);
						if (nextEl.style.display == 'block') {
							nextEl.style.display = 'none';
						} else {
							nextEl.style.display = 'block';
						}

					}
				});
			})
		}


	});

	const HandleExitSystem = async (event) => {
		event.preventDefault();
		localStorage.removeItem('token');
		localStorage.clear();
		window.location.href = `${window.location.origin}/login`;
    	window.location.reload();
		window.$chatwoot.reset();
	}

	useEffect(() => {
		(function(d, t) {
		  var BASE_URL = "https://chat.transtecworld.com/";
		  var g = d.createElement(t), s = d.getElementsByTagName(t)[0];
		  g.src = BASE_URL + "/packs/js/sdk.js";
		  g.async = true;
		  g.onload = function() {
			window.chatwootSDK.run({
			  websiteToken:  'VoRowJw2CxbeuknsgqF5E4VY',
			  baseUrl: BASE_URL
			});
		  };
		  s.parentNode.insertBefore(g, s);
		})(document, "script");
	  }, []);

	return (
		<nav class="navbar navbar-expand-lg navbar-dark bg-custom wp-1 -w">
			<div class="container-fluid">
				<a class="navbar-brand" href="#"><img src={logo} alt="Transtec World" width="150" height="40" /></a>
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="main_nav">
					<ul class="navbar-nav">
						<Link to="/home" ><button type="button" class="btn btn-sm btn-default bg-custom mx-1">Home</button></Link>
						<div class="btn-group">
							<button button type="button" class="btn btn-sm btn-default bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Cadastros</button>
							<ul class="dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
							<>
								{(permissions.dev || permissions.admin || permissions.gGeral) && (<li><Link class="dropdown-item" to="/cadastro_entidade" >Cadastros Gerais</Link></li>)}
								{(permissions.cliente || permissions.dev) && (<li><Link class="dropdown-item" to={`/cadastro_entidade?codCadastro=1`} target="_blank" rel="noopener noreferrer">Cadastros de Clientes</Link></li>)}
								{(permissions.cliente || permissions.dev) && (<li><Link class="dropdown-item" to={`/cadastro_entidade?codCadastro=2`} target="_blank" rel="noopener noreferrer">Cadastros de Motoristas</Link></li>)}
								{(permissions.cliente || permissions.dev) && (<li><Link class="dropdown-item" to={`/cadastro_entidade?codCadastro=3`} target="_blank" rel="noopener noreferrer">Cadastros de Vistoriadores</Link></li>)}
								{(permissions.dev || permissions.admin || permissions.gGeral) && (<li><Link class="dropdown-item" to="/consulta_entidade" >Consultas de cadastros</Link></li>)}
							</>
							</ul>
						</div>
						<div class="btn-group">
							<button button type="button" class="btn btn-sm btn-default bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Agendamentos</button>
							<ul class="dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
								<li><Link class="dropdown-item" to="/consulta_devolucao" >Devolução de Importação</Link></li>
								<li><Link class="dropdown-item" to="/consulta_agendamento" >Agendamento de Exportação</Link></li>
							</ul>
						</div>
						{(permissions.dev || permissions.admin || permissions.gGeral) && (<div class="btn-group">
							<button button type="button" class="btn btn-sm btn-default bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Dashboards</button>
							<ul class="dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
								<li><Link class="dropdown-item" to="/dashboard" >Agendamentos por Hora</Link></li>
								<li><Link class="dropdown-item" to="/dashboard2" >Agendamentos por Dia</Link></li>
							</ul>
						</div>)}								
						{(permissions.dev || permissions.admin || permissions.gGeral) && (<div class="btn-group">
							<button button type="button" class="btn btn-sm btn-default bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Gerenciar</button>
							<ul class="dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
								<li><Link class="dropdown-item" to="/usuarios_ant" >Usuarios Antomar</Link></li>
							</ul>
						</div>)}								

						{/*{(permissions.dev || permissions.admin) && (<div class="btn-group">
							<button type="button" class="btn btn-sm btn-default bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Contas a Receber</button>
							<ul class="dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
								<li><Link class="dropdown-item" to="/faturamento" >Faturamento</Link></li>
								<li><Link class="dropdown-item" to="/consciliacao_bancaria" >Consciliação Bancaria</Link></li>
								<li><Link class="dropdown-item" to="/consciliacao_bancaria_avaria" >Consciliação Bancaria (avaria)</Link></li>
							</ul>
						</div>)}*/}
						{/*<div class="btn-group">
                <button type="button" class="btn btn-sm btn-default bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Constas a Pagar</button>
            <ul class="dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
			<li><Link class="dropdown-item" to="/orcamento_compra" >Orçamento Compra</Link></li>
			<li><Link class="dropdown-item" to="/orcamento_lista" >Orçamento Lista</Link></li>
            </ul>
        </div>
		<div class="btn-group">
                <button type="button" class="btn btn-sm btn-default bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Compras</button>
            <ul class="dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
			<li><Link class="dropdown-item" to="/orcamento_compra" >Orçamento Compra</Link></li>
			<li><Link class="dropdown-item" to="/orcamento_lista" >Orçamento Lista</Link></li>
            </ul>
        </div>
		<div class="btn-group">
                <button type="button" class="btn btn-sm btn-default bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Funcionarios</button>
            <ul class="dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
            <li><Link class="dropdown-item" to="/funcionario_cadastro" >Cadastro Funcionário</Link></li>
			<li><Link class="dropdown-item" to="/funcionario_lista" >Lista de Funcionários</Link></li>
			<div><hr class="divider"/></div>
			<li><Link class="dropdown-item" to="/permissoes_cadastro" >Cadastro de Permissões</Link></li>
			<li><Link class="dropdown-item" to="/permissoes_lista" >Lista de Permissões</Link></li>
			<div><hr class="divider"/></div>
            <li><Link class="dropdown-item" to="/servico_cadastro" >Cadastrar de Cargos</Link></li>
			<li><Link class="dropdown-item" to="/servico_lista" >Lista de Cargos</Link></li>
            </ul>
        </div>
		<div class="btn-group">
                <button type="button" class="btn btn-sm btn-default bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">RDV</button>
            <ul class="dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
            <li><Link class="dropdown-item" to="/rdv_cadastro" >Cadastro RDV</Link></li>
			<li><Link class="dropdown-item" to="/rdv_dados" >Dados RDV</Link></li>
			<li><Link class="dropdown-item" to="/rdv_eventos" >Eventos RDV</Link></li>
			<li><Link class="dropdown-item" to="/rdv_lista" >Lista RDV</Link></li>
            </ul>
  		</div>*/}
					</ul>
					<ul class="navbar-nav ms-auto">
					{(permissions.dev || permissions.admin || permissions.manager) && (<div class="btn-group">
							<button type="button" class="btn btn-sm btn-default bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Configurações</button>
							<ul class="dropdown-menu dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
								<li><Link class="dropdown-item" to="/manutencao">Manutenção</Link></li>
								<li><a class="dropdown-item" to="/cadastro_insumo" >Versão do software</a></li>
							</ul>
						</div>)}
						<span className="mx-2 mt-2" style={{fontSize: '0.9rem'}}>{localStorage.userName}</span>
						<Link to="/login"><button type="button" class="btn btn-sm btn-danger me-1 mx-1" onClick={HandleExitSystem}>Sair</button></Link>

					</ul>

				</div>
			</div>
		</nav>

	);
};

export default Navbar;