import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import Aba2 from "./aba2";
import Aba6 from "./aba6";
import Aba1 from "./aba1";
import Aba3 from "./aba3";
import Aba4 from "./aba4";
import Aba5 from "./aba5";
import axios from "axios";

const FaturamentoModal = () => {
  const navigate = useNavigate();
  const [fatAba1, setFatAba1] = useState({
    lanc_os:"",
    id_emp: "",
    fechamento: "",
    id__entidade: "",
    clientw:"",
    id_entidade_pag: "",
    cliente_pag:"",
    data_emissao_nf: "",
    data_vencimento: "",
    data_vencimentoOrig: "",
    valor: "",
    valor_receb: "",
    valor_pagm: "",
    id_bank: "",
    id_forma_pag: "",
    number_nf:"",
    boleto: "",
    comment:"",
    id_conta: "",
    id_status: "",
    nome:"",
    id_usuario: ""});
  const [fatAba2, setFatAba2] = useState([]);
  const [fatAba5, setFatAba5] = useState([]);
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const idLanc = new URLSearchParams(window.location.search).get('idlanc');
  const [codLanc, setCodLanc ] = useState(null);
  const [handleButtonBlockSave, setHandleButtonBlockSave] = useState(false)
  const [isReady, setIsReady] = useState(false); // Novo estado

  useEffect(() => {
    if (idLanc != null) {
      setCodLanc(idLanc);
    }
  },[idLanc])

  useEffect(() => {
      setIsReady(true); // Define como pronto quando codLanc não é mais nulo
  }, [codLanc]);

  useEffect(() => {    
    // Find the modal element using a ref
    const modalElement = document.getElementById('modal2');
    // Find all close buttons for the modal
    const closeModalButtons = document.querySelectorAll('[data-bs-dismiss="modal"]');

    // Function to handle modal close
    const handleCloseModal = () => {
      modalElement.classList.remove('show');
      modalElement.style.display = 'none';
      modalElement.setAttribute('aria-modal', 'false');
      modalElement.setAttribute('aria-hidden', 'true');
      document.body.classList.remove('modal-open');
      removeModalBackdrop();
      navigate('/faturamento');
      window.location.reload();
    };

    // Attach event listeners to each close button
    closeModalButtons.forEach(button => {
      button.addEventListener('click', handleCloseModal);
    });

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      closeModalButtons.forEach(button => {
        button.removeEventListener('click', handleCloseModal);
      });
    };
  }, []);

  const removeModalBackdrop = () => {
    const backdropElement = document.querySelector('.modal-backdrop');
    if (backdropElement) {
        backdropElement.remove();
    }
  };



  const handleSave = async () => {
    setHandleButtonBlockSave(true);

    if (!codLanc) {
      await handleSubmitAba1();
    } else {
      await handleUpdateAba1();
    };
    
    setTimeout(async () => {
      if (codLanc) {
        await handleSaveAba2();
        await handleSaveAba5();
        window.location.href = `${window.location.origin}/faturamento?idlanc=${codLanc}`;
      } else {
        toast.error('Erro ao definir codLanc!', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }, 2000);
  };

  const handleSubmitAba1 = async () => {
    try {
      const response = await fetch(`${parsedURL}post/lanc/Fat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(fatAba1)
      });

      if (!response.ok) {
        throw new Error('Erro ao enviar os dados.');
      }

      const data = await response.json();
      console.log('Dados enviados com sucesso:', data.idlanc);
      setCodLanc(data.idlanc);
      await handleSaveOtherAbas(data.idlanc);
      toast.success('Lançamento realizado', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleUpdateAba1 = async () => {
    try {
      const response = await fetch(`${parsedURL}put/lanc/fat_id?idlanc=${codLanc}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(fatAba1)
      });

      if (!response.ok) {
        throw new Error('Erro ao enviar os dados.');
      }

      const data = await response.json();
      console.log('Dados enviados com sucesso:', data);
      await handleSaveOtherAbas(codLanc);
      toast.success('Lançamento atualizado', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleSaveOtherAbas = async (idlanc) => {
    try {
      await handleSaveAba2(idlanc);
      await handleSaveAba5(idlanc);
      window.location.href = `${window.location.origin}/faturamento?idlanc=${idlanc}`;
    } catch (error) {
      toast.error('Erro ao salvar dados das outras abas!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSubmitAba5 = async (idlanc) => {
    try {
      const newData = fatAba5.filter(data => !data.id_centF); // Filtra os dados que não possuem id_centF
      const response = await axios.post(
        `${parsedURL}post/lanc/fat_center_coust?idFat=${idlanc}`,
        newData,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateAba5 = async (idlanc) => {
    try {
      const updatedData = fatAba5.filter(data => data.id_centF);
      const response = await axios.put(
        `${parsedURL}put/lanc/fat_center_coust_id?idFat=${idlanc}`,
        updatedData,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveAba5 = async (idlanc) => {
    await handleSubmitAba5(idlanc);
    await handleUpdateAba5(idlanc);
  };

  const handleSubmitAba2 = async (idlanc) => {
    const newData = fatAba2.filter(data => !data.id_cc); // Filtra os dados que não possuem id_cc
    try {
      const response = await axios.post(
        `${parsedURL}post/lanc/fat_cntr?idFat=${idlanc}`,
        newData,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateAba2 = async (idlanc) => {
    try {
      const updatedData = fatAba2.filter(data => data.id_cc); // Filtra os dados que possuem id_cc
      const response = await axios.put(
        `${parsedURL}put/lanc/fat_services_id?idFat=${idlanc}`,
        updatedData,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveAba2 = async (idlanc) => {
    await handleSubmitAba2(idlanc);
    await handleUpdateAba2(idlanc);
  };

  return (
    <>
      <div class="modal fade" id="modal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable ">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Faturamento</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {/*<nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <button class="nav-link active form-label" id="nav-1-tab" data-bs-toggle="tab" data-bs-target="#nav-1" type="button" role="tab" aria-controls="nav-1" aria-selected="true">Lançamento</button>
                  <button class="nav-link form-label" id="nav-2-tab" data-bs-toggle="tab" data-bs-target="#nav-2" type="button" role="tab" aria-controls="nav-2" aria-selected="false">Container</button>
                  <button class="nav-link form-label" id="nav-4-tab" data-bs-toggle="tab" data-bs-target="#nav-4" type="button" role="tab" aria-controls="nav-4" aria-selected="false">Forma</button>
                  <button class="nav-link form-label" id="nav-3-tab" data-bs-toggle="tab" data-bs-target="#nav-5" type="button" role="tab" aria-controls="nav-5" aria-selected="false">C. Custo</button>
                 {/*<button class="nav-link form-label" id="nav-4-tab" data-bs-toggle="tab" data-bs-target="#nav-6" type="button" role="tab" aria-controls="nav-6" aria-selected="false">Tributos</button>
                </div>
              </nav>*/}

              <div className="row align-items-end">
              <div className="col-12 ">
              <legend className="form-label">Dados da Fatura</legend>
                  <Aba1 fat={fatAba1} setFat={setFatAba1}/>
              </div>
              <div><hr className="divider" /></div>
              <div className="col-8 ">
              <legend className="form-label">Containers / Serviços</legend>
                  <Aba2  rowData={fatAba2} setRowData={setFatAba2}/>
              </div>
              <div className="col-4 ">
              <legend className="form-label">Centro de Custos</legend>
                  <Aba5 rowData={fatAba5} setRowData={setFatAba5}/>
              </div>
             {/*     <Aba4 />
                  <Aba6 /> */}

              </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" disabled={handleButtonBlockSave}  class="btn btn-primary"  onClick={handleSave}>Salvar</button>
            </div>
              {/*
              "onClick={idLanc != null ? handleUpdate : handleSubmit}"
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active " id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab" tabindex="0">
                </div>
                <div class="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab" tabindex="0">
                </div>
                <div class="tab-pane fade" id="nav-4" role="tabpanel" aria-labelledby="nav-4-tab" tabindex="0">
                </div>
                <div class="tab-pane fade" id="nav-5" role="tabpanel" aria-labelledby="nav-5-tab" tabindex="0">
                </div>
                <div class="tab-pane fade" id="nav-6" role="tabpanel" aria-labelledby="nav-6-tab" tabindex="0">
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default FaturamentoModal;