import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import DataFat from "./cad_entidade_data_fat";
import { Button, Form, Modal, Spinner, Image } from 'react-bootstrap';
import FaTrash from '@mui/icons-material/Close';
import { useAuth } from "../../context/AuthContext";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const CadEntidade = () => {
  const [email, setEmail] = useState('assist.financeiro@transtecworld.com');
  const [password, setPassword] = useState('Tec1906@');
  const [dataApiBrasil, setDataApiBrasil] = useState();
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const [visiblecpf,setVisibleCpf] = useState(false)
  const [visibleCNPJ,setVisibleCNPJ] = useState(false)
  const [howModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null); 
  const [filePreview, setFilePreview] = useState(null); 
  const [idEntidade, setIdEntidade] = useState(null); 
  const { permissions } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id_entidade = searchParams.get('id_ent');
  const codCadastro = searchParams.get('codCadastro');
  const [close,setClose] = useState(false);
  const [contato,setContato] = useState([]);

  const [formState, setFormState] = useState({
    razao_social: "",
    fantasia: "",
    cnpj_cpf: "",
    cnh: "",
    inscricao_estadual: "",
    logradouro: "",
    cep: "",
    numero: "",
    cidade: "",
    doc_type: "",
    obs: "",
    estado: "",
    A: 0,
    C: 0,
    T: 0,
    M: 0,
    F: 0,
    V: 0
  });

  useEffect(() => {
    handleLoginApiBrasil();
  }, []);
  
  useEffect(() => {
    if(formState.doc_type === '2'){
    setVisibleCpf(true)
    setVisibleCNPJ(false)
    } else if (formState.doc_type === '1'){
      setVisibleCNPJ(true)
      setVisibleCpf(false)
    }
    else{
      setVisibleCNPJ(false)
      setVisibleCpf(false)
    }
    
  }, [formState.doc_type]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "cnpj_cpf") {
      const formattedValue = formState.doc_type === '1' ? formatCNPJ(value) : formatCPF(value);
      setFormState(prevState => ({
        ...prevState,
        [name]: formattedValue
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const response = await axios.post(`${parsedURL}post/cadastro/entidade`, formState, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Dados enviados com sucesso:', response.data);
      toast.success('Cadastro efetuado com sucesso!', {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(response.data.id_ent)
      await handlePostCont(response.data.id_ent)
      await handleFileUpload(response.data.id_ent)
      console.log(close)
      if (close === true){
        setTimeout(() => {
          window.close();
        }, 2000);
      }
      else{
        window.location.reload();
      }
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handlePostCont = async (idEntidade) => {
    try {
      const response = await axios.post(
        `${parsedURL}post/cadastro/contato?id_ent=${idEntidade}`,contato,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }}
      );
      console.log(response);
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
    }
  }

  const handleLoginApiBrasil = async () => {
    try {
      const res = await axios.post('https://gateway.apibrasil.io/api/v2/login', {
        email: email,
        password: password
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      setDataApiBrasil(res.data);
      //setError(null);
      console.log(res.data)
    } catch (err) {
      //setResponse(null);
    }
  };

  const handleGetCont = async() =>{
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/contato?id_ent=${id_entidade}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content[0]));
        setContato(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const formatCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const formatCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/\D/g, "");
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  };

  const handleGetCEP = async () => {
    const fetchCPFData = new Promise(async (resolve, reject) => {
      console.log(formState.cep);
      if (formState.cep !== null && formState.cep !== '') {
        try {
          const result = await axios.get(
            `https://viacep.com.br/ws/${formState.cep}/json/?callback=callback_name`,
            {
              headers: {
                'accept': 'application/json'
              },
            }
          );
          if (result.status === 200) {
            // Remove o "callback_name(" do início e o ")" do fim
            const dataCepString = result.data.replace(/^callback_name\(|\);$/g, '');
            const dataCep = JSON.parse(dataCepString);
  
            setFormState(prevState => ({
              ...prevState,
              estado: dataCep.uf || '',
              logradouro: (dataCep.logradouro || '') + ', ' + (dataCep.bairro || '') + ', ' + (dataCep.complemento || ''),
              cidade: dataCep.localidade || '',
              numero: dataCep.unidade || ''
            }));
            console.log(JSON.stringify(dataCep));
            resolve("CEP validado com sucesso!");
          }
        } catch (error) {
          console.error("Erro ao buscar o CEP:", error);
          reject(error);
        }
      } else {
        reject("Não existe dados para realizar a busca!");
      }
    });
  
    toast.promise(
      fetchCPFData,
      {
        pending: {
          render() {
            return "Buscando dados...";
          },
          icon: true,
        },
        success: {
          render({ data }) {
            return `${data}`;
          },
          icon: "🟢",
        },
        error: {
          render({ data }) {
            return `Erro: ${data.message}`;
          },
          icon: "🔴",
        }
      }
    );
  };

  const handleGetCPF = async () => {
    setFormState(
      prevState => ({
        ...prevState,
      razao_social: "",
      fantasia: "",
      cnh: "",
      inscricao_estadual: "",
      logradouro: "",
      cep: "",
      numero: "",
      cidade: "",
      obs: "",
      estado: "",
      a: 0,
      c: 0,
      t: 0,
      m: 0,
      f: 0
    }));
    if (formState.doc_type === '2') {
        if (formState.cnpj_cpf !== null && formState.cnpj_cpf !== '') {
            const fetchCPFData = new Promise(async (resolve, reject) => {
                try {
                    const verifica = await axios.get(
                        `${parsedURL}get/entidade/verifica_doc?doc=${formState.cnpj_cpf}`,
                        {
                            headers: {
                                'accept': 'application/json',
                                'Authorization': `Bearer ${token}`
                            },
                        }
                    );
                    if (verifica.status === 200) {
                        reject(new Error('Já existe cadastro realizado com esse CPF.'));
                    }
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        // Proceed with API call to get CPF data
                        try {
                            const response = await axios.post(
                                `https://cluster.apigratis.com/api/v2/dados/cpf`,
                                {
                                    "cpf": formState.cnpj_cpf
                                },
                                {
                                    headers: {
                                        'accept': 'application/json',
                                        'DeviceToken': '20eb8941-3bf4-4593-b6b3-578b2a16658c',
                                        'Authorization': `Bearer ${dataApiBrasil.authorization.token}`
                                    },
                                }
                            );

                            if (response.data.error === false) {
                                console.log(JSON.stringify(response.data));

                                const contentResponse = response.data.response.content;
                                const nome = contentResponse.nome.conteudo.nome || '';

                                setFormState(prevState => ({
                                    ...prevState,
                                    razao_social: nome,
                                }));

                                console.log(contentResponse.pesquisa_enderecos);

                                if (contentResponse.pesquisa_enderecos.existe_informacao === 'SIM') {
                                    const endereco = contentResponse.pesquisa_enderecos.conteudo;
                                    console.log(endereco[0].logradouro)
                                    setFormState(prevState => ({
                                        ...prevState,
                                        logradouro: (endereco[0].logradouro || '') + ', ' + (endereco.bairro || ''),
                                        cep: endereco[0].cep || '',
                                        estado: endereco[0].estado || '',
                                        cidade: endereco[0].cidade || '',
                                        numero: endereco[0].numero || ''
                                    }));
                                    console.log(formState)
                                    resolve("CPF validado com sucesso!");
                                } else {
                                    resolve("CPF validado com sucesso! Não existe endereços cadastrados nesse CPF!");
                                }
                            } else {
                                reject(new Error('Erro na resposta da API, verifique os dados e tente novamente.'));
                            }
                        } catch (error) {
                            console.log(error);
                            reject(error);
                        }
                    } else {
                        reject(new Error('Erro ao verificar o documento.'));
                    }
                }
            });
            toast.promise(
                fetchCPFData,
                {
                    pending: {
                        render() {
                            return "Buscando dados...";
                        },
                        icon: true,
                    },
                    success: {
                        render({ data }) {
                            return `${data}`;
                        },
                        icon: "🟢",
                    },
                    error: {
                        render({ data }) {
                            return `Erro: ${data.message}`;
                        },
                        icon: "🔴",
                    }
                }
            );

        } else {
            toast.error('Não existem dados para buscar', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    } else if (formState.doc_type === '1') {
        console.log('Buscar via CNPJ');
        if (formState.cnpj_cpf !== null && formState.cnpj_cpf !== '') {
            const fetchCNPJData = new Promise(async (resolve, reject) => {
                try {
                    const verifica = await axios.get(
                        `${parsedURL}get/entidade/verifica_doc?doc=${formState.cnpj_cpf}`,
                        {
                            headers: {
                                'accept': 'application/json',
                                'Authorization': `Bearer ${token}`
                            },
                        }
                    );
                    if (verifica.status === 200) {
                        reject(new Error('Já existe cadastro realizado com esse CNPJ.'));
                    }
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        // Proceed with API call to get CNPJ data
                        try {
                            const response = await axios.post(
                                `https://cluster.apigratis.com/api/v2/dados/cnpj`,
                                {
                                    "cnpj": formState.cnpj_cpf
                                },
                                {
                                    headers: {
                                        'accept': 'application/json',
                                        'DeviceToken': '7399ab25-6c78-44cb-9a59-a43f8c2b6cc1',
                                        'Authorization': `Bearer ${dataApiBrasil.authorization.token}`
                                    },
                                }
                            );

                            if (response.data.error === false) {
                                console.log(JSON.stringify(response.data));
                                const cnpjResponse = response.data.response.cnpj;
                                console.log(cnpjResponse)
                                setFormState(prevState => ({
                                    ...prevState,
                                    fantasia: cnpjResponse.nome_fantasia || '',
                                    razao_social: cnpjResponse.empresa.razao_social || '',
                                    logradouro: (cnpjResponse.tipo_logradouro || '') + ' ' + (cnpjResponse.logradouro || '') + ', ' + (cnpjResponse.bairro || '') + ', ' + (cnpjResponse.complemento || ''),
                                    cep: cnpjResponse.cep || '',
                                    estado: cnpjResponse.uf || '',
                                    cidade: (cnpjResponse.municipio && cnpjResponse.municipio.descricao) || '',
                                    numero: cnpjResponse.numero || ''
                                }));
                                resolve("CNPJ validado com sucesso!");
                            } else {
                                reject(new Error('Erro na resposta da API, verifique os dados e tente novamente.'));
                            }
                        } catch (error) {
                            console.log(error);
                            reject(error);
                        }
                    } else {
                        reject(new Error('Erro ao verificar o documento.'));
                    }
                }
            });
            toast.promise(
                fetchCNPJData,
                {
                    pending: {
                        render() {
                            return "Buscando dados...";
                        },
                        icon: true,
                    },
                    success: {
                        render({ data }) {
                            return `${data}`;
                        },
                        icon: "🟢",
                    },
                    error: {
                        render({ data }) {
                            return `Erro: ${data.message}`;
                        },
                        icon: "🔴",
                    }
                }
            );

        } else {
            toast.error('Não existem dados para buscar', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

    } else {
        console.log('Não tem como buscar...')
        toast.error('Selecione um tipo de documento', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
};

  //anexar arquivos
  const handleAttachFiles = () => {
    setShowModal(true);    
  };

  const [isLoading, setIsLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const handleAppendFile = (event) => {
    const arquivoSelecionados = event.target.files[0];
    if (arquivoSelecionados && arquivoSelecionados.type.startsWith('image/')){
      setFile(arquivoSelecionados);
      setFilePreview(URL.createObjectURL(arquivoSelecionados));
    }
    else{
      toast.error('Apenas arquivos de imagem são permitidos!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",});
    }
  };
  
  const handleSaveImage = () => {
    if (file) {
      setShowModal(false);
    } else {
      toast.error('Nenhuma imagem selecionada!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleDeleteImage = () => {
    setFile(null);
    setFilePreview(null);
  };

  const handleFileUpload = async (idEntidade) => {
    {/*if (!file) {
      toast.error('Nenhuma imagem selecionada para upload!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }*/}
    if (file){
    const formData = new FormData();
    formData.append('arquivo', file);
  
    try {
      const response = await axios.post(
        `${parsedURL}post/cadastro/moto_vist?id_ent=${idEntidade}`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      if (response.status === 200) {
        console.log(response.data.content);
        {/*toast.success('Imagem enviada com sucesso!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });*/}
      }
    } catch (error) {
      toast.error('Erro ao enviar a imagem!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
}
  console.log(id_entidade)

  const getPhoto = async () => {
    axios.get(`${parsedURL}get/foto_entidade?id_ent=${id_entidade}`, {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        setHasPhoto(true)
        const imageData = response.data.image;
        const imageUrl = `data:image/jpeg;base64,${imageData}`;
        document.getElementById('foto_entidade').src = imageUrl;
      })
      .catch(error => {
        console.error('Erro ao carregar a imagem:', error);
      });
  }

  const getEnt = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/entidade3?id_ent=${id_entidade}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content[0]));
        setFormState(response.data.content[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const addContact = () => {
    const newContact = {
      nome: '',
      email: '',
      telefone: '',
      tipo_contato: null
    };
    setContato([...contato, newContact]);
  };
  const typeMap = {
    1: 'Comercial',
    2: 'Financeiro'
  };
  useEffect(() => {
    if (id_entidade) {
        getPhoto();
        getEnt();
        handleGetCont();
    }
  }, [id_entidade, token]);
  
  const handleCheckboxChange = (event) => {
    const { id, value } = event.target;
    setFormState((prevState) => ({
      A: id === 'A' ? '1' : '0',
      C: id === 'C' ? '1' : '0',
      F: id === 'F' ? '1' : '0',
      T: id === 'T' ? '1' : '0',
      M: id === 'M' ? '1' : '0',
      V: id === 'V' ? '1' : '0',
    }));
  };

  useEffect(() => {
    if (codCadastro === '1') {
      setFormState((prevState) => ({
        ...prevState,
        C: 1
      }))
    } else if (codCadastro == '2') {
      setFormState((prevState) => ({
        ...prevState,
        M: 1,
        doc_type: '2'
      }));
    } else if (codCadastro === '3') {
      setFormState((prevState) => ({
        ...prevState,
        V: 1
      }));
    }
    if (codCadastro != null){
      setClose(true)
    }
  }, [codCadastro]);
  
  const onCellValueChanged = (params) => {
    if (params.colDef.field === 'tipo_contato') {
      const updatedContacts = contato.map((contact, index) => {
        if (index === params.node.rowIndex) {
          return { ...contact, tipo_contato: parseInt(params.newValue, 10) };
        }
        return contact;
      });
      setContato(updatedContacts);
    }
  };

const [hasPhoto,setHasPhoto] = useState(false);

console.log(contato)
  return (
    <>
      {(!id_entidade) && <Navbar/>}
      <div className="box-center">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button class="nav-link active form-label" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home"
            type="button" role="tab" aria-controls="nav-home" aria-selected="true">Informações Gerais </button>
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
            <legend className="form-label mt-3">Cadastro</legend>
                  {(!codCadastro) && (<div class="row">
                    <div class="col-auto mt-3">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" value={formState.A} name="formType" id="A" onChange={handleCheckboxChange}></input>
                          <label class="form-check-label" for="flexCheckDefault">Armador</label>
                        </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" value={formState.C} name="formType" id="C" onChange={handleCheckboxChange}></input>
                        <label class="form-check-label" for="flexCheckDefault">Cliente</label>
                      </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" value={formState.F} name="formType" id="F" onChange={handleCheckboxChange}></input>
                          <label class="form-check-label" for="flexCheckDefault">Fornecedor</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" value={formState.T} name="formType" id="T" onChange={handleCheckboxChange}></input>
                          <label class="form-check-label" for="flexCheckDefault">Transportadora</label>
                        </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" value={formState.M} name="formType" id="M" onChange={handleCheckboxChange}></input>
                        <label class="form-check-label" for="flexCheckDefault">Motorista</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" value={formState.V} name="formType" id="V" onChange={handleCheckboxChange}></input>
                        <label class="form-check-label" for="flexCheckDefault">Emp.Vistoria / Vistoriador</label>
                      </div>
                    </div>
                  </div>)}
            <form class="row g-3 mt-3" onSubmit={handleSubmit}>
              <div class="col-md-4">
                <div className="col">
                  <label for="doc_type" class="form-label fw-bold">Tipo de Documento:</label>
                  <select value={formState.doc_type} onChange={handleChange} name="doc_type" id="inputState" class="form-select transparent-input" required>
                    <option selected value={0}>Selecione o Tipo Doc.</option>
                    <option value={1}>CNPJ</option>
                    <option value={2}>CPF</option>
                  </select>
                </div>
                <div className="col mt-3">
                  <label for="cnpjcpf" class="form-label fw-bold">CNPJ/CPF:</label>
                  <div className="input-group">
                    <input type="text" class="form-control transparent-input" id="cnpjcpf" name="cnpj_cpf"  required value={formState.cnpj_cpf} maxLength={(formState.doc_type === '1' ? 14 : 0) || (formState.doc_type === '2' ? 11 : 0) || (formState.doc_type === 1 ? 14 : 0) || (formState.doc_type === 2 ? 11 : 0)} onChange={handleChange} />
                    <div class="btn btn-primary" onClick={handleGetCPF}>Validar</div>
                  </div>
                </div>
                {((visiblecpf) || (visibleCNPJ)) && (<div class="col mt-3">
                  {(visibleCNPJ) && (<label for="razaosocial" class="form-label fw-bold">Razão Social:</label>)}
                  {(visiblecpf) && (<label for="razaosocial" class="form-label fw-bold">Nome:</label>)}
                  <input type="text" class="form-control transparent-input" id="razaosocial" name="razao_social" value={formState.razao_social} onChange={handleChange} required/>
                </div>)}
                {(visibleCNPJ) && (<div class="col mt-3">
                  <label for="nomefantasia" class="form-label fw-bold">Nome Fantasia:</label>
                  <input type="text" class="form-control transparent-input " id="nomefantasia" name="fantasia" value={formState.fantasia} onChange={handleChange} required/>
                </div>)}
                {(visiblecpf) && (<div class="col mt-3">
                  <label for="cnh" class="form-label fw-bold">CNH:</label>
                  <input type="text" class="form-control transparent-input" id="cnh" name="cnh" value={formState.cnh} onChange={handleChange} required/>
                </div>)}
                {(visibleCNPJ) && (<div class="col mt-3">
                  <label for="inscestadual" class="form-label fw-bold">Inscrição Estadual:</label>
                  <input type="text" class="form-control transparent-input" id="inscestadual" name="inscricao_estadual" value={formState.inscricao_estadual} onChange={handleChange}/>
                </div>)}
              </div>
              <div class="col-md-4">
                <div class="col">
                  <label for="cep" class="form-label fw-bold">CEP:</label>
                  <div class="input-group">
                    <input type="text" class="form-control transparent-input" id="cep" name="cep" value={formState.cep} onChange={handleChange} required/>
                    <div className="btn btn-primary input-group-text" onClick={handleGetCEP}>Procurar </div>
                  </div>
                </div>  
                <div class="col mt-3">
                  <label for="estado" class="form-label fw-bold">UF:</label>
                  <input type="text" class="form-control transparent-input" id="estado" name="estado" value={formState.estado} onChange={handleChange} required/>
                </div>
                <div class="col mt-3">
                  <label for="cidade" class="form-label fw-bold">Cidade:</label>
                  <input type="text" class="form-control transparent-input" id="cidade" name="cidade" value={formState.cidade} onChange={handleChange} required/>
                </div>
                <div class="col mt-3">
                  <label for="numero" class="form-label fw-bold">Número:</label>
                  <input type="text" class="form-control transparent-input" id="numero" name="numero" value={formState.numero} onChange={handleChange} required/>
                </div>
                <div class="col mt-3">
                <label for="endereco" class="form-label fw-bold">Endereço:</label>
                <input type="text" class="form-control transparent-input" id="endereco" name="logradouro" value={formState.logradouro} onChange={handleChange} required/>
              </div>
              </div>
              <div class="col-md-4 mt-3" >
              
                {((formState.M === "1"||formState.M === 1)  || ((formState.V === "1"||formState.V === 1) && (formState.doc_type ==="2"))) && (<div className="d-grid">
                  <button className="btn btn-info mt-3" type="button" onClick={handleAttachFiles}>Anexar Foto Motorista/Vistoriador</button>
                </div>)}
                {((formState.M === "1"||formState.M === 1)  || ((formState.V === "1"||formState.V === 1) && (formState.doc_type ==="2"))) && (<div>
                <legend className="text-center mt-3">Foto:</legend>
                <div className="mt-5 ms-5 rounded border border-1 d-flex justify-content-center align-items-center "
                  style={{ width: '200px', height: '200px' }}>
                  <img className="m-auto" id="foto_entidade" src="" alt="SEM FOTO" style={{ width: '200px', height: '200px' }} />
                </div>
                </div>)}
              </div>
              <div class="col-md-6">
              <Button onClick={addContact} className="mb-4">Adicionar Contato</Button>
                <div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
                  <AgGridReact
                    rowData={contato}
                    columnDefs={[
                      { headerName: "Nome", field: "nome", width: 200, editable: true ,cellStyle: { backgroundColor: '#f0f0f0' },
                      cellRenderer: (params) => {return params.value || 'Digite o nome';}},
                      { headerName: "E-Mail", field: "email", width: 235, editable: true, cellStyle: { backgroundColor: '#f0f0f0' },
                      cellRenderer: (params) => {return params.value || 'Digite o e-mail';} },
                      { headerName: "Telefone", field: "telefone", width: 140 , editable: true, cellStyle: { backgroundColor: '#f0f0f0' },
                      cellRenderer: (params) => {return params.value || 'Digite o telefone';}},
                      { headerName: "Tipo Contato", field: "tipo_contato", width: 130, 
                        cellEditor:'agSelectCellEditor', 
                        cellEditorParams:{values:['1','2'],
                          cellRenderer: (option) => {return typeMap[option.value] || 'Selecione..';} 
                        },
                        editable:true,
                        cellStyle: { backgroundColor: '#f0f0f0' },
                        valueFormatter: (params) => typeMap[params.value] || 'Selecione...',
                        cellRenderer: (params) => {
                          const displayValue = typeMap[params.value] || 'Selecione...';
                          return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <span>{displayValue}</span>
                              <span style={{ fontSize: '8px' }}>▼</span>
                            </div>
                          );
                        },
                        valueParser: (params) => {
                          return params.newValue ? parseInt(params.newValue, 10) : null;
                        }
                      }
                    ]}
                    onCellValueChanged={onCellValueChanged}
                  />
                </div>
                </div>
              <div class="col-md-12">
                <label for="exampleFormControlTextarea1" class="form-label fw-bold mt-3">Observação:</label>
                <textarea class="form-control transparent-input" rows="5"></textarea>
              </div>
              <div class="col-md-12">
                {/*<div class="form-check">
                  <input class="form-check-input" type="checkbox" id="gridCheck" />
                  <label class="form-check-label hfx" for="gridCheck">
                    Inadimplente
                  </label>
              </div>*/}
              </div>
              <div class="col-md-12">
                <button class="btn btn-success">Salvar</button>
              </div>
            </form>
          </div>
          <div class="tab-pane" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0"><DataFat /></div>
        </div>
      </div>
      <Modal show={howModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Anexar Foto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Selecione a imagem:</Form.Label>
            <Form.Control type="file" id='file' accept="image/*" onChange={handleAppendFile} />
            {filePreview && (
              <div class="mt-3 ms-3" style={{ position: 'relative', display: 'inline-block' }}>
                <img src={filePreview} alt="Imagem Selecionada" style={{ width: '100px', height: '100px' }} />
                <FaTrash
                  onClick={handleDeleteImage}
                  style={{ position: 'absolute', top: '5px', right: '5px', cursor: 'pointer', color: 'red' }}
                />
              </div>
            )}
          </Form.Group>
          {isLoading && (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </Spinner>
              </div>
            )}
            {uploaded && (
              <div className="d-flex justify-content-center">
                <div class="alert alert-success d-flex align-items-center" role="alert">
                  <CheckBoxIcon className="text-success"/>
                  <div>
                    Imagem enviada com sucesso!
                  </div>
                </div>
              </div>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSaveImage}>
            Enviar
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer stacked
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default CadEntidade;
