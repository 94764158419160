import React, { useState, useEffect, Suspense, lazy, useRef, useCallback } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { Checkbox, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_BR } from "@ag-grid-community/locale"
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from "../navbar/navbar";
import "../../style/style.css";
import { Search } from '@mui/icons-material';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AgendamentoModal from "./agendamento_modal"
import { Row, Table, Modal, Form } from 'react-bootstrap';
import { useAuth } from "../../context/AuthContext";
//import Patio2 from "./p2"
//import Patio3 from "./p3"
//import Patio6 from "./p6"
//import Patio7 from "./p7"
//import PatioMD from "./pmd"
//import PatioGJA from "./pgja"
//import PatioRC from "./prc"

const ConsultaAgend = () => {
  const [rowData, setRowData] = useState();
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const codAgendamento = searchParams.get('codAgendamento');
  const openModal = searchParams.get('openModal');
  const Patio2 = lazy(() => import('./p2'));
  const Patio3 = lazy(() => import('./p3'));
  const Patio6 = lazy(() => import('./p6'));
  const Patio7 = lazy(() => import('./p7'));
  const PatioMD = lazy(() => import('./pmd'));
  const PatioGJA = lazy(() => import('./pgja'));
  const PatioRC = lazy(() => import('./prc'));
  const [activeTab, setActiveTab] = useState('p2'); // Aba ativa inicial
  const { permissions } = useAuth();
  const gridRef = useRef();

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const onFirstDataRendered = useCallback((params) => {
    // Aplicar o filtro padrão aqui
    params.api.setFilterModel({
      'codStatus': {
        type: 'text',
        filter: 'Em Análise'
      }
    });
  }, []);

  useEffect(() => {
    handleGetAgendamento();
  }, []);
  
  const handleGetAgendamento = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/agendamento`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setRowData(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  const parseDate = (dateString) => {
    if (!dateString) return null;
  
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('/');
    const [hours, minutes, seconds] = timePart.split(':');
  
    // Mês em JavaScript é 0-indexed, então subtraímos 1
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };
  
  const formatDate = (date) => {
    if (!date || isNaN(date.getTime())) return 'Data Inválida';
    
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };
  
  
  useEffect(() => {
    if (codAgendamento !== null) {
      const modalElement = document.getElementById('modal2');
      modalElement.classList.add('show');
      modalElement.style.display = 'block';
      modalElement.setAttribute('aria-modal', 'true');
      modalElement.setAttribute('role', 'dialog');
      document.body.classList.add('modal-open');
      const backdropElement = document.createElement('div');
      backdropElement.classList.add('modal-backdrop', 'fade', 'show');
      document.body.appendChild(backdropElement);
    }
  }, [codAgendamento]);

  useEffect(() => {
    if (openModal !== null) {
      const modalElement = document.getElementById('modal2');
      modalElement.classList.add('show');
      modalElement.style.display = 'block';
      modalElement.setAttribute('aria-modal', 'true');
      modalElement.setAttribute('role', 'dialog');
      document.body.classList.add('modal-open');
      const backdropElement = document.createElement('div');
      backdropElement.classList.add('modal-backdrop', 'fade', 'show');
      document.body.appendChild(backdropElement);
    }
  }, [openModal]);

   
  const colDefs = [
    { field: "booking", headerName: "Booking", filter: true, width: 120, floatingFilter: true },
    { field: "codArmador", headerName: "Armador", filter: true, width: 150, floatingFilter: true },
    { field: "codResp", headerName: "Exportador", filter: true, width: 170, floatingFilter: true },
    { field: "codTipo", headerName: "Tipo de Container", filter: true, width: 170, floatingFilter: true },
    { field: "cargotype", headerName: "Padrão", filter: true, width: 120, floatingFilter: true },
    { field: "quant", headerName: "Qtd.", filter: true, width: 80, floatingFilter: true },
    { field: "codStatus", headerName: "Status", filter: true, width: 130, cellClassRules: {
      'bg-warning': (params) => params.value === 'Em análise',
      'bg-success': (params) => params.value === 'Aprovado',
      'bg-danger': (params) => params.value === 'Reprovado',
      'bg-secondary': (params) => params.value === 'Cancelado',
      'rounded': (params) => ['Em análise', 'Aprovado', 'Reprovado'].includes(params.value),
    }, floatingFilter: true},
    { field: "dataCreate", headerName: "Data Solicitação", filter: true, width: 180, floatingFilter: true ,valueGetter: (params) => {
      return parseDate(params.data.dataCreate);
    },
    valueFormatter: (params) => {
      return formatDate(params.value);
    },
    sort: 'asc',
    sortingOrder: ['asc', 'desc'],
    comparator: (valueA, valueB) => {
      if (!valueA && !valueB) return 0;
      if (!valueA) return 1;
      if (!valueB) return -1;
      return valueA.getTime() - valueB.getTime();
    }},
    { field: "vistoria", headerName:"Vistoria", filter: true, width:100, valueFormatter: (params) => {
      if (params.value === 0) {
        return "Não";
      } else if (params.value === 1) {
        return "Sim";
      } else {
        return params.value;
      }
    }, floatingFilter: true },
    {
      field: "codAgendamento",
      headerName: "",
      filter: false,
      width: 155,
      cellRenderer: (params) => (
        <Link type="button" to={`/consulta_agendamento?codAgendamento=${params.data.codAgendamento}`}
        className="btn mx-2 btn-primary">
          Ver Detalhes
        </Link>
      )
    }
  ];

  function TabContent({ activeTab }) {
    switch (activeTab) {
      case 'p2':
        return <Patio2 />;
      case 'p3':
        return <Patio3 />;
      case 'p6':
        return <Patio6 />;
      case 'p7':
        return <Patio7 />;
      case 'pmd':
        return <PatioMD />;
      case 'pgja':
        return <PatioGJA />;
      case 'prc':
        return <PatioRC />;
      default:
        return null;
    }
  } 
  
  return (
    <>
      <Navbar></Navbar>
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button class="nav-link active form-label" id="nav-1-tab" data-bs-toggle="tab" data-bs-target="#nav-1" 
          type="button" role="tab" aria-controls="nav-1" aria-selected="true">Consultas</button>
          {(permissions.admin || permissions.dev || permissions.gGeral || permissions.agendamento || permissions.supDoc)
           &&(<button class="nav-link form-label" id="nav-2-tab" data-bs-toggle="tab" data-bs-target="#nav-2" type="button" 
           role="tab" aria-controls="nav-2" aria-selected="false">Janelas</button>)}
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active " id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab" tabindex="0">
          <div class="row align-items-start g-1">
            <div class="col-12">
              <div className="box-center">
                <div className="card col-md-12 mx-auto">
                  <div className="card-body pt-2">
                    <div className="row d-flex justify-content-between"> 
                      <legend className="form-label mt-3 col">Agendamentos de Retirada de Vazio</legend> 
                      <div className="col-auto mt-3"> 
                        <Link type="button" to={`/consulta_agendamento`} className="btn mx-2 btn-primary" data-bs-target="#modal2" data-bs-toggle="modal">Nova Solicitação</Link> 
                        </div>
                      </div>
                      <div><hr className="divider" /></div>
                      <div className="box-center1">
                        <div className=" rounded card">
                          <div className="card col-md-12 mx-auto">
                            <div className="ag-theme-quartz" style={{ height: 600 }}>
                              <AgGridReact
                                localeText={AG_GRID_LOCALE_BR}
                                ref={gridRef}
                                rowData={rowData}
                                columnDefs={colDefs}
                                rowSelection="unique"
                                pagination={true}
                                onFirstDataRendered={onFirstDataRendered}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AgendamentoModal/>
        </div>
      </div>
      <div class="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab" tabindex="0">
        <nav>
          <div class="nav nav-tabs" id="nav-tab2" role="tablist">
            <button className={`nav-link form-label ${activeTab === 'p2' ? 'active' : ''}`} id="nav-patio2" onClick={() => handleTabClick('p2')} type="button" role="tab" aria-controls="nav-patio2" aria-selected={activeTab === 'p2'}>Pátio 2</button>
            <button className={`nav-link form-label ${activeTab === 'p3' ? 'active' : ''}`} id="nav-patio3" onClick={() => handleTabClick('p3')} type="button" role="tab" aria-controls="nav-patio3" aria-selected={activeTab === 'p3'}>Pátio 3</button>
            <button className={`nav-link form-label ${activeTab === 'p6' ? 'active' : ''}`} id="nav-patio6" onClick={() => handleTabClick('p6')} type="button" role="tab" aria-controls="nav-patio6" aria-selected={activeTab === 'p6'}>Pátio 6</button>
            <button className={`nav-link form-label ${activeTab === 'p7' ? 'active' : ''}`} id="nav-patio7" onClick={() => handleTabClick('p7')} type="button" role="tab" aria-controls="nav-patio7" aria-selected={activeTab === 'p7'}>Pátio 7</button>
            <button className={`nav-link form-label ${activeTab === 'pmd' ? 'active' : ''}`} id="nav-pmd" onClick={() => handleTabClick('pmd')} type="button" role="tab" aria-controls="nav-pmd" aria-selected={activeTab === 'pmd'}>Pátio Marginal Direita</button>
            <button className={`nav-link form-label ${activeTab === 'pgja' ? 'active' : ''}`} id="nav-pgja" onClick={() => handleTabClick('pgja')} type="button" role="tab" aria-controls="nav-pgja" aria-selected={activeTab === 'pgja'}>Pátio Guarujá</button>
            <button className={`nav-link form-label ${activeTab === 'prc' ? 'active' : ''}`} id="nav-prc" onClick={() => handleTabClick('prc')}  type="button" role="tab" aria-controls="nav-prc" aria-selected={activeTab === 'prc'}>Pátio Rio Claro</button>
          </div>
        </nav>
        <div className="tab-content">
          <Suspense fallback={<div>Loading...</div>}>
            <TabContent activeTab={activeTab} />
          </Suspense>
        </div>
      </div>      
    </>
  );
};

export default ConsultaAgend;