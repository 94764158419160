import React from 'react';
import Navbar from "../navbar/navbar";


const Unauthorized = () => {
  return (
    <>
    <Navbar></Navbar>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      fontSize: '4rem',
      fontWeight: 'bold',
      color: 'red'
    }}>
      Acesso Negado
      <p style={{ fontSize: '2rem', marginTop: '1rem' }}>
        Você não possui permissão para acessar esta página.
      </p>
    </div>
  </>
  );
};

export default Unauthorized;