import React, { useEffect } from 'react';

const LOGIN_KEY = 'user_login_time';
const SESSION_DURATION = 24 * 60 * 60 * 1000; // 24 horas em milissegundos

const SessionTimeout = ({ children }) => {
  useEffect(() => {
    const checkSession = () => {
      const loginTime = localStorage.getItem(LOGIN_KEY);
      const token = localStorage.getItem('token');
      console.log(!token || !loginTime)
      if (!token || !loginTime) {
        if (window.location.pathname !== '/login') {
          window.location.href = `${window.location.origin}/login`;
        }
        return;
      }

      const currentTime = Date.now();
      const timeElapsed = currentTime - parseInt(loginTime, 10);
      console.log(timeElapsed > SESSION_DURATION)
      if (timeElapsed > SESSION_DURATION) {
        localStorage.clear();
        if (window.location.pathname !== '/login') {
          window.location.href = `${window.location.origin}/login`;
        }
      }
    };

    //checkSession();

    const interval = setInterval(checkSession, 1000 * 60); // Verifica a cada minuto

    console.log(interval)

    return () => clearInterval(interval);
  }, []);

  return <>{children}</>;
};

export default SessionTimeout;