import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import CadastroDevolucao from "./cad_devolucao"


const DevolucaoModal = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Find the modal element using a ref
    const modalElement = document.getElementById('modal2');
    // Find all close buttons for the modal
    const closeModalButtons = document.querySelectorAll('[data-bs-dismiss="modal"]');

    // Function to handle modal close
    const handleCloseModal = () => {
      modalElement.classList.remove('show');
      modalElement.style.display = 'none';
      modalElement.setAttribute('aria-modal', 'false');
      modalElement.setAttribute('aria-hidden', 'true');
      document.body.classList.remove('modal-open');
      removeModalBackdrop();
      navigate('/consulta_devolucao');
      window.location.reload();
    };

    // Attach event listeners to each close button
    closeModalButtons.forEach(button => {
      button.addEventListener('click', handleCloseModal);
    });

    // Cleanup function to remove event listeners when component unmounts
    return () => {
      
      closeModalButtons.forEach(button => {
        button.removeEventListener('click', handleCloseModal);
      });
    };
  }, []);
  
  
  const removeModalBackdrop = () => {
    const backdropElement = document.querySelector('.modal-backdrop');
    if (backdropElement) {
        backdropElement.remove();
    }

};

return (
  <>
    <div class="modal fade" id="modal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" backdrop="static">
      <div class="modal-dialog modal-xl ">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5 fw-bold" id="exampleModalLabel">Cadastro de Devolução</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active " id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab" tabindex="0">
              <div className="row col-md-12 ms-2 mb-3 text-danger">Serão aceitos somente containers com o direcionamento do armador aos nossos terminais. </div>
                <CadastroDevolucao />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </>
);
};
export default DevolucaoModal;