import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../style/style.css";
import Navbar from "../navbar/navbar";
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_BR } from "@ag-grid-community/locale";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ADDPerson from '@mui/icons-material/PersonAdd';

const UsuariosAntomar = () => {
  const [rowData, setRowData] = useState();
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const [totals, setTotals] = useState([]);
  const gridRef = useRef();

  const [formState, setFormState] = useState({
    Usuario: "",
    Senha: "",
    Patio: "",
    Santos: "",
    Guaruja: "",
    Cadastros: 0,
    Turn_In: 0,
    Entradas: 0,
    Manutencao: 0,
    Estimativas: 0,
    Booking: 0,
    Vendas: 0,
    Saidas: 0,
    Armazem: 0,
    Estoque: 0,
    Relatorios: 0
  })
  
  const [newuser, setNewUser] = useState({
    Usuario:"",
    Senha:""
  })
  
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [excludeModal, setExcludeModal] = useState(false);

  useEffect(() => {
    handleGetUsuariosAnt();
  }, []);

  const handleRowDoubleClick = (params) => {
    const user = params.data.Usuario;
    console.log(user);
    handleGetSingleUsuario(user);
  };

  const handleGetSingleUsuario = async (user) => {
    console.log(user)
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/usuario_ant?user=${user}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setFormState(response.data.content[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleDeleteUsuario = async () => {
    console.log()
    try {
      const response = await axios.delete(
        `${parsedURL}delete/cadastro/usuario_ant?user=${formState.Usuario}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );
  
      if (response.status === 200) {
        console.log(`Usuário ${formState.Usuario} excluído com sucesso!`);
        handleGetUsuariosAnt();
        toast.success(`Usuario ${formState.Usuario} excluído com sucesso!`, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateCadastro = async () => {
    console.log(formState)
    try {
      const response = await axios.put(
        `${parsedURL}put/cadastro/usuario_ant?user=${formState.Usuario}`, formState,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        toast.success('Cadastro atualizado!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
    }
  }

  const handleGetUsuariosAnt = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/usuarios_ant`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setRowData(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handlePostUser = async () => {
    try {
      console.log(newuser)
      const response = await axios.post(
        `${parsedURL}post/cadastro/usuario_ant`,newuser,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        toast.success('Usuario Criado com Sucesso!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        await handleGetUsuariosAnt();
        setNewUser("","")
      }      
    } catch (error) {
      console.log(error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
    }
  }

  const columns = [
    { field: "Usuario", headerName: "Usuario", width: 300 },
  ];
  {/*{ field: "Senha", headerName: "Senha", width: 80 },
  { field: "Patio", headerName: "Patio", width: 80 },
  { field: "Santos", headerName: "Santos", width: 80 },
  { field: "Guaruja", headerName: "Guaruja", width: 90 },
  {
    field: "Cadastros", headerName: "Cadastros", width: 100, cellRenderer: (params) => {
      switch (params.value) {
        case "1": return 'Alteração';
        case "2": return 'Consulta';
        case "3": return 'Invisível';
        default: return params.value;
      }
    }
  },
  {
    field: "Turn_In", headerName: "Turn_In", width: 90, cellRenderer: (params) => {
      switch (params.value) {
        case "1": return 'Alteração';
        case "2": return 'Consulta';
        case "3": return 'Invisível';
        default: return params.value;
      }
    }
  },
  {
    field: "Entradas", headerName: "Entradas", width: 100, cellRenderer: (params) => {
      switch (params.value) {
        case "1": return 'Alteração';
        case "2": return 'Consulta';
        case "3": return 'Invisível';
        default: return params.value;
      }
    }
  },
  {
    field: "Manutencao", headerName: "Logistica", width: 120, cellRenderer: (params) => {
      switch (params.value) {
        case "1": return 'Alteração';
        case "2": return 'Consulta';
        case "3": return 'Invisível';
        case "4": return 'Exclusão';
        default: return params.value;
      }
    }
  },
  {
    field: "Estimativas", headerName: "Estimativas", width: 110, cellRenderer: (params) => {
      switch (params.value) {
        case "1": return 'Alteração';
        case "2": return 'Consulta';
        case "3": return 'Invisível';
        case "4": return 'Criatividade';
        case "5": return 'Sem Valor';
        default: return params.value;
      }
    }
  },
  {
    field: "Booking", headerName: "Booking", width: 90, cellRenderer: (params) => {
      switch (params.value) {
        case "1": return 'Alteração';
        case "2": return 'Consulta';
        case "3": return 'Invisível';
        case "4": return 'Exclusão';
        default: return params.value;
      }
    }
  },
  {
    field: "Vendas", headerName: "Vendas", width: 80, cellRenderer: (params) => {
      switch (params.value) {
        case "1": return 'Alteração';
        case "2": return 'Consulta';
        case "3": return 'Invisível';
        case "4": return 'Exclusão';
        default: return params.value;
      }
    }
  },
  {
    field: "Saidas", headerName: "Saidas", width: 80, cellRenderer: (params) => {
      switch (params.value) {
        case "1": return 'Alteração';
        case "2": return 'Consulta';
        case "3": return 'Invisível';
        default: return params.value;
      }
    }
  },
  {
    field: "Armazem", headerName: "Armazem", width: 100, cellRenderer: (params) => {
      switch (params.value) {
        case "1": return 'Alteração';
        case "2": return 'Consulta';
        case "3": return 'Invisível';
        default: return params.value;
      }
    }
  },
  {
    field: "Estoque", headerName: "Estoque", width: 100, cellRenderer: (params) => {
      switch (params.value) {
        case "1": return 'Alteração';
        case "2": return 'Consulta';
        case "3": return 'Invisível';
        default: return params.value;
      }
    }
  },
  {
    field: "Relatorios", headerName: "Relatorios", width: 100, cellRenderer: (params) => {
      switch (params.value) {
        case "1": return 'Comum';
        case "2": return 'Gerência';
        default: return params.value;
      }
    }
  },*/}

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value,
    );
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
  }
  const handleChange2 = (event) => {
    const { name, value } = event.target;
    setNewUser(prevState=>({
      ...prevState,
      [name]: value.toUpperCase()
    }))
  }

  const handleCheckboxChange = (field) => (event) => {
    setFormState(prevState => ({
      ...prevState,
      [field]: event.target.checked ? 'T' : 'F'
    }));
  };

  const handleModalCancel = () => {
    setModalIsOpen(false);
  };
  const openModal=()=>{
    setModalIsOpen(true)
  }
  const handleModalConfirm = () => {
    setModalIsOpen(false);
    handlePostUser();
  };
  const handleExcludeModalCancel = () => {
    setExcludeModal(false);
  };
  const openExcludeModal=()=>{
    setExcludeModal(true)
  }
  const handleExcludeModalConfirm = () => {
    setExcludeModal(false);
    handleDeleteUsuario();
  };

  return (
    <>
      <Navbar />
      <div className="box-center1">
        <div className="row d-flex justify-content-between">
          <div className="card col-md-3">
            <div className="example-header bg-warning rounded border">
              <span className="mx-2 mt-2 mb-2"><b>Filtro:</b></span>
              <input type="text" className="mx-2 mt-2 mb-2 rounded border" id="filter-text-box" placeholder="Digite..."
                onInput={onFilterTextBoxChanged}
              />
              <div type="button" className='btn btn-success float-end btn-sm mt-1 me-2' onClick={openModal}><ADDPerson/></div>
            </div>
            <div className="ag-theme-quartz" style={{ height: 550 }}>
              <AgGridReact
                ref={gridRef}
                localeText={AG_GRID_LOCALE_BR}
                rowData={rowData}
                columnDefs={columns}
                pagination={false}
                rowSelection="single"
                onRowDoubleClicked={handleRowDoubleClick}
              />
            </div>
          </div>
          <div className='card col-md-9'>
            <div className='row g-2'>
              <div className='col-md-12'>
                <div className='d-flex justify-content-between align-items-center'>
                <legend className='mt-2 mb-3 fw-bold col-md-4'>Permissões:</legend>
                <div type="label" className='fw-bold col-md-4 fs-4'>{formState.Usuario}</div>
                <div type="button" className='btn btn-danger btn-sm' onClick={openExcludeModal}>EXCLUIR</div>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <Form.Group controlId="santosCheckbox">
                    <Form.Check
                      type="checkbox"
                      inline
                      checked={formState.Santos === 'T'}
                      onChange={handleCheckboxChange('Santos')}
                      label={<span className="fw-bold">Santos</span>}
                    />
                  </Form.Group>
                  <Form.Group controlId="guarujaCheckbox">
                    <Form.Check
                      type="checkbox"
                      inline
                      checked={formState.Guaruja === 'T'}
                      onChange={handleCheckboxChange('Guaruja')}
                      label={<span className="fw-bold">Guarujá</span>}
                    />
                  </Form.Group>
                  <div className='row'>
                    <div className='col-auto'>
                      <label for="Senha" class="form-label fw-bold">Senha:</label>
                    </div>
                    <div className='col'>
                      <input type="text" class="form-control transparent-input" id="Senha" name="Senha"
                        value={formState.Senha} onChange={handleChange} />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-auto'>
                      <label for="Patio" class="form-label fw-bold">Patio:</label>
                    </div>
                    <div className='col'>
                      <input type="text" class="form-control transparent-input" id="Patio" name="Patio"
                        value={formState.Patio} onChange={handleChange} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div>
                  <label for="Cadastros" class="form-label fw-bold mt-2">Cadastros:</label>
                  <select value={formState.Cadastros} onChange={handleChange} name="Cadastros"
                    id="inputState" class="form-select transparent-input">
                    <option selected value="0">Selecione...</option>
                    <option value="1">Alteração</option>
                    <option value="2">Consulta</option>
                    <option value="3">Invisivel</option>
                  </select>
                </div>
                <div>
                  <label for="Turn_In" class="form-label fw-bold mt-2">Turn_In:</label>
                  <select value={formState.Turn_In} onChange={handleChange} name="Turn_In"
                    id="inputState" class="form-select transparent-input">
                    <option selected value="0">Selecione...</option>
                    <option value="1">Alteração</option>
                    <option value="2">Consulta</option>
                    <option value="3">Invisivel</option>
                  </select>
                </div>
                <div>
                  <label for="Entradas" class="form-label fw-bold mt-2">Entradas:</label>
                  <select value={formState.Entradas} onChange={handleChange} name="Entradas"
                    id="inputState" class="form-select transparent-input">
                    <option selected value="0">Selecione...</option>
                    <option value="1">Alteração</option>
                    <option value="2">Consulta</option>
                    <option value="3">Invisivel</option>
                  </select>
                </div>
                <div>
                  <label for="Manutencao" class="form-label fw-bold mt-2">Logistica:</label>
                  <select value={formState.Manutencao} onChange={handleChange} name="Manutencao"
                    id="inputState" class="form-select transparent-input">
                    <option selected value="0">Selecione...</option>
                    <option value="1">Alteração</option>
                    <option value="2">Consulta</option>
                    <option value="3">Invisivel</option>
                    <option value="4">Exclusão</option>
                  </select>
                </div>
                <div>
                  <label for="Estimativas" class="form-label fw-bold mt-2">Estimativas:</label>
                  <select value={formState.Cadastros} onChange={handleChange} name="Estimativas"
                    id="inputState" class="form-select transparent-input">
                    <option selected value="0">Selecione...</option>
                    <option value="1">Alteração</option>
                    <option value="2">Consulta</option>
                    <option value="3">Invisivel</option>
                    <option value="4">Criatividade</option>
                    <option value="4">Sem Valor</option>
                  </select>
                </div>
                <div>
                  <label for="Booking" class="form-label fw-bold mt-2">Booking:</label>
                  <select value={formState.Booking} onChange={handleChange} name="Booking"
                    id="inputState" class="form-select transparent-input">
                    <option selected value="0">Selecione...</option>
                    <option value="1">Alteração</option>
                    <option value="2">Consulta</option>
                    <option value="3">Invisivel</option>
                    <option value="4">Exclusão</option>
                  </select>
                </div>
              </div>
              <div className='col-md-6'>
                <div>
                  <label for="Vendas" class="form-label fw-bold mt-2">Vendas:</label>
                  <select value={formState.Vendas} onChange={handleChange} name="Vendas"
                    id="inputState" class="form-select transparent-input">
                    <option selected value="0">Selecione...</option>
                    <option value="1">Alteração</option>
                    <option value="2">Consulta</option>
                    <option value="3">Invisivel</option>
                    <option value="4">Exclusão</option>
                  </select>
                </div>
                <div>
                  <label for="Saidas" class="form-label fw-bold mt-2">Saidas:</label>
                  <select value={formState.Saidas} onChange={handleChange} name="Saidas"
                    id="inputState" class="form-select transparent-input">
                    <option selected value="0">Selecione...</option>
                    <option value="1">Alteração</option>
                    <option value="2">Consulta</option>
                    <option value="3">Invisivel</option>
                  </select>
                </div>
                <div>
                  <label for="Armazem" class="form-label fw-bold mt-2">Armazem:</label>
                  <select value={formState.Armazem} onChange={handleChange} name="Armazem"
                    id="inputState" class="form-select transparent-input">
                    <option selected value="0">Selecione...</option>
                    <option value="1">Alteração</option>
                    <option value="2">Consulta</option>
                    <option value="3">Invisivel</option>
                  </select>
                </div>
                <div>
                  <label for="Estoque" class="form-label fw-bold mt-2">Estoque:</label>
                  <select value={formState.Estoque} onChange={handleChange} name="Estoque"
                    id="inputState" class="form-select transparent-input">
                    <option selected value="0">Selecione...</option>
                    <option value="1">Alteração</option>
                    <option value="2">Consulta</option>
                    <option value="3">Invisivel</option>
                  </select>
                </div>
                <div>
                  <label for="Relatorios" class="form-label fw-bold mt-2">Relatorios:</label>
                  <select value={formState.Relatorios} onChange={handleChange} name="Relatorios"
                    id="inputState" class="form-select transparent-input">
                    <option selected value="0">Selecione...</option>
                    <option value="1">Comum</option>
                    <option value="2">Gerencia</option>
                  </select>
                </div>
                <div class="d-grid">
                  <label for="" class="row form-label fw-bold mt-3"></label>
                  <div type="button" className='btn btn-primary mt-3' onClick={updateCadastro}>Salvar Alterações</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {(modalIsOpen) && (<div isOpen={true} onRequestClose={handleModalCancel} contentLabel="Confirmação de Saldo Baixo">
          <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-warning"><b className="mx-auto">NOVO USUARIO</b></div>
                <div className="modal-body">
                  <input type='text' placeholder='USUARIO' onChange={handleChange2} maxLength={20} value={newuser.Usuario} class="form-control transparent-input" name='Usuario' id='Usuario'/>
                  <input type='text' placeholder='SENHA' onChange={handleChange2} maxLength={10} value={newuser.Senha} class="form-control transparent-input mt-3" name='Senha' id='Senha'/>
                </div>
                <div className="modal-footer mx-auto">
                  <div type="button" className="btn btn-success" onClick={handleModalConfirm}>OK</div>
                  <div type="button" className="btn btn-danger" onClick={handleModalCancel}>Cancelar</div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
        {(excludeModal) && (<div isOpen={true} onRequestClose={handleExcludeModalCancel} contentLabel="ATENÇÃO">
          <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-warning"><b className="mx-auto">NOVO USUARIO</b></div>
                <div className="modal-body">
                  <p>Deseja seguir com a exclusão do usuario?</p>
                </div>
                <div className="modal-footer mx-auto">
                  <div type="button" className="btn btn-success" onClick={handleExcludeModalConfirm}>SIM</div>
                  <div type="button" className="btn btn-danger" onClick={handleExcludeModalCancel}>NÃO</div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default UsuariosAntomar;