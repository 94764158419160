import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../style/style.css"
import { Link, useNavigate, useLocation, Await } from 'react-router-dom';
import axios from "axios";
import Navbar from "../navbar/navbar";
import Search from "@mui/icons-material/Search.js";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Button, Form, Modal, Spinner,Alert } from 'react-bootstrap';
import Remove from '@mui/icons-material/DeleteForever';
import { useAuth } from "../../context/AuthContext";
import PrintIcon from '@mui/icons-material/Print';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import moment from 'moment';
import Larrow from '@mui/icons-material/SubdirectoryArrowRight';
import ADDPerson from '@mui/icons-material/PersonAdd';

const CadAgendamento = () => {
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const [margens, setMargens] = useState([]);
  const [tipocc, setTipocc] = useState([]);
  const [armador, setArmador] = useState([]);
  const [statusagend, setStatusAgend] = useState([]);
  const [cargo_type, setCargoType] = useState([]);
  const [destino, setDestino] = useState([]);
  const [veicul, setVeiculo] = useState([]);
  const [entidad, setEnti] = useState([]);
  const [empVisto, setEmpVist] = useState([]);
  const [patio, setPatio] = useState([]);
  const [commodities, setCommo] = useState([]);
  const [commodities2, setCommo2] = useState([]);
  const [selectType, setSelectType] = useState();
  const [selectDest, setSelectDest] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDestinoModal, setShowDestinoModal] = useState(false);
  const [showEmpVistModal, setShowEmpVistModal] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const codAgendamento = searchParams.get('codAgendamento');
  const [admin, setAdmin] = useState(false);
  const [handleButtonBlockSave, setHandleButtonBlockSave] = useState(false);
  const [status, setStatus] = useState(true);
  const [howModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [saldo, setSaldo] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [datahora, setdatahora] = useState({ data: "", hora: "" });
  const [motoristasTemp, setMotoristasTemp] = useState([]);
  const [showMotorModal, setshowMotorModal] = useState(false);
  const [codAgend, setCodAgend] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const { permissions } = useAuth();
  const [files, setFiles] = useState([])
  const [errors, setErrors] = useState({});
  const [reg, setReg] = useState(false);
  const [regVist, setRegVist] = useState(false);

  //formulario de motorista
  const [newMotorista, setNewMotorista] = useState({
    cpf: "",
    nome: "",
    cnh: "",
    placa: "",
    hora: null,
    container: null
  });
  const [ids, setIds] = useState({
    codMotorista: null,
    codVeiculo: null,
  });

  //formulario principal
  const [formState, setFormState] = useState({
    codArmador: "",
    booking: "",
    codResp: "",
    navio: "",
    viagem: "",
    codDestino: "",
    codMargem: "",
    codTipo: "",
    quant: "",
    cargotype: "",
    vistoria: 0,
    agendamentodata: "",
    temp: "",
    ventilacao: "",
    umidade: "",
    probes: "",
    genset: 0,
    pesoliq: "",
    obs: "",
    tipoCarga: "",
    pesoCarga: "",
    codStatus: 1,
    codTypeAgenda: 1,
    numeroAgendamento: "EXP#00001",
    idPatio: "",
    vistOption: 0,
    nomeVist: "",
    cpfVist: "",
    idVist:"",
    empVist:"",
    outros:"",
  });

  const update = {
    agendamentodata: formState.agendamentodata,
    codStatus: formState.codStatus,
    idPatio: formState.idPatio !== null ? formState.idPatio : '0',
    vistOption: formState.vistOption !== undefined ? formState.vistOption : '0',
    Obs: formState.obs
  };
  const [seletor,setSeletor] = useState({
    commodities:""
  });
  //UseEffect primario na abertura da pagina
  useEffect(() => {
    handleGetMargens();
    handleGetTipo();
    handleGetStatusAgend();
    handleGetCargoType();
    //handleGetDest();
    //handleGetVeiculo();
    handleGetEnt();
    handleGetArmador();
    handleGetPatios();
    handleGetCommo();
    handleGetCommo2();
  }, []);
  
  //Verifica o codAgendamento e exibe os dados no formulario
  useEffect(() => {
    const fetchData = async () => {
      if (codAgendamento !== null) {
        await handleGetAgendamento2();
        if (permissions.cliente === true) {
          setReadOnly(true);
        }
        setCodAgend(codAgendamento);
        await handleGetMotAgend();
      }
    };
    fetchData();
  }, [codAgendamento]);
  
  //Checa se é um Update ou um Post
  const handleCheck = async () => {
    if(formState.outros !== ""){
      await postCommodities();
    }
    console.log(formState.tipoCarga)
    let codVist;
    //let codAgenda;
    if(!validateFields()){
      return;
    };
    setHandleButtonBlockSave(true);
    if(formState.idVist === '' && (formState.vistoria === 1 || formState.vistoria === '1')){
      codVist = await postVistoria();
      console.log(codVist.codVist)
    }
    else{
      codVist = {codVist:''};
    }

    if (!codAgendamento) {
      await handleSubmit(codVist.codVist);
    } else {
      await handleUpdate();
    };
    //if (!codAgenda){
    //    codAgenda = {codAgend: 0};
    //}
    console.log(codAgend)
    //await handlePostMotAgend(codAgenda.codAgenda);
    //await handlePutMotAgend(ids.codVeiculo, ids.codMotorista);
    console.log(!modalIsOpen === true)
    if (modalIsOpen) {
      setTimeout(() => {
        window.location.href = `${window.location.origin}/consulta_agendamento`
      }, 1000)
    }
  }

  const motorSaldo = () =>{
    let saldo
      saldo=formState.quant-motoristasTemp.length;
      console.log(formState.quant)
      console.log(motoristasTemp.length)
      console.log(saldo)
      return saldo;
  }
  const novoSaldo = motorSaldo();
  console.log('Novo saldo:', novoSaldo);
  
  //POST
  const handleSubmit = async (codVist) => {
    console.log(codVist)
    let formulario;
    if (codVist)
    {
      formulario = {
        ...formState,
        idVist: codVist
      }
    }
    else{
      formulario = {
        ...formState,
        idVist: 0
      }
    }
    console.log(formulario);
    //let codAgenda
    try {
      const response = await axios.post(`${parsedURL}post/cadastro/agendamento`, 
        formulario
        , {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Dados enviados com sucesso:', response.data);
      toast.success('Agendamento cadastrado com sucesso!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      //if (response.status === 200){
      //  codAgenda = response.data.codAgendamento;
      //  console.log(response.data)
      //}
      setHandleButtonBlockSave(false);
      // Faça o que for necessário após o envio bem-sucedido dos dados
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setHandleButtonBlockSave(false);
    }
    //return{codAgenda}
  };
  //Obtem o saldo de unidades referente a seleçao do formulario
  const getSaldo = async () => {
    try {
      const agendamentoDate = new Date(formState.agendamentodata);
      const year = agendamentoDate.getFullYear();
      const month = String(agendamentoDate.getMonth() + 1).padStart(2, '0'); // getMonth() retorna 0-11
      const day = String(agendamentoDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;

      const response = await axios.get(
        `${parsedURL}get/cadastro/saldo?id_patio=${formState.idPatio}&dataJanela=${formattedDate}&codArmador=${formState.codArmador}&codTipo=${formState.codTipo}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      let saldojson;
      // Verificar se response.data é um array ou um objeto e acessar os dados corretamente
      if (Array.isArray(response.data.content) && response.data.content.length > 0) {
        const data = response.data.content[0]; // Supondo que você esteja interessado no primeiro registro
        console.log(data)
        console.log(formState.cargotype)

        if (formState.cargotype === 1) {
          saldojson = data.CG;
        } else if (formState.cargotype === 2) {
          saldojson = data.CF;
        } else if (formState.cargotype === 3) {
          saldojson = data.ES;
        } else if (formState.cargotype === 4) {
          saldojson = data.SC;
        }
      } else {
        console.error('Estrutura de dados inesperada:', response.data);
      }

      setSaldo(saldojson);
      console.log(saldo)
      return saldojson;
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
    }
  }

  //Faz a verificaçao do saldo e notifica caso esteja baixo
  const handleUpdate = async () => {
    console.log(formState.codStatus)
    if(formState.codStatus === 2 || formState.codStatus === '2'){
      const saldo = await getSaldo();
      if ((saldo <= 5 && formState.codTipo !== 4 || formState.codTipo !== 12)) {
        setModalMessage(`O saldo é ${saldo}. Tem certeza que deseja continuar?`);
        setModalIsOpen(true);
      } else if ((saldo <= 10 && (formState.codTipo === 4 || formState.codTipo === 12))) {
        setModalMessage(`O saldo é ${saldo}. Tem certeza que deseja continuar?`);
        setModalIsOpen(true);
    }
    } else {
      await executeUpdate();
    }
  };
  //UPDATE
  const executeUpdate = async () => {
    console.log(update)
    console.log(formState.vistOption)
    try {
      const response = await axios.put(
        `${parsedURL}put/cadastro/agendamento?codAgendamento=${codAgendamento}`,
        update,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      console.log('Dados enviados com sucesso:', response.data);
      toast.success('Agendamento atualizado!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setHandleButtonBlockSave(false);
      setTimeout(() => {
        window.location.href = `${window.location.origin}/consulta_agendamento`
      }, 1000)
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
    };
  }

  const postVistoria = async() =>{
    let codVist = null
    if (formState.idVist===''){
      try {
        const postVist = await axios.post(
          `${parsedURL}post/cadastro/vistoria`,
          {
            cnpj_cpf: formState.cpfVist,
            razao_social: formState.nomeVist,
            doc_type: 'CPF',
            V: 1
          },
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (postVist.status === 200) {
          console.log(postVist.data.idVist);
          codVist = postVist.data.idVist;
        }
      } catch (error) {
        console.error("Erro ao enviar Vistoriador:", error);
      }
      return {codVist}
    }
  }

  const vistBlur = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/vistoria2?cnpj_cpf=${formState.cpfVist}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.status === 208) {
        console.log(response.data.content[0].id_entidade)
        setFormState((prevState) => ({
          ...prevState,
          nomeVist: response.data.content[0].razao_social,
          idVist: response.data.content[0].id_entidade,
        }));
        setRegVist(true)
        console.log(formState.idVist)
      }
      else if(response.status === 200){
        toast.error('Cadastro não encontrado\n Redirecionando para a pagina de cadastro...', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          window.open('/cadastro_entidade?codCadastro=3', '_blank', 'noopener,noreferrer');
        }, 3000);
        console.log(response)
      }
    } catch (error) {
      console.log(error)
    }
    return {regVist}
  };

  //Gera o doc PDF
  const gerarPdf = async (codMA) => {
    try {
      const response = await axios.get(
        `${parsedURL}get/gerar_pdf/agendamento_exportacao?codMA=${codMA}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          responseType: 'blob',
        }
      );
      if (response.status === 200) {
        // Cria um arquivo blob a partir da resposta
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);
        window.open(url);
      } else {
        console.error('Erro ao gerar o PDF:', response.status);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const gerarPdfLote = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/gerar_pdf/agendamentos_exportacao?codAgendamento=${codAgendamento}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          responseType: 'blob',
        }
      );
      if (response.status === 200) {
        // Cria um arquivo blob a partir da resposta
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);
        window.open(url);
      } else {
        console.error('Erro ao gerar o PDF:', response.status);
      }
    } catch (error) {
      console.log(error);
    }
  }
 
  const gerarPdfVistoria = async () =>{
    try {
      const response = await axios.get(
        `${parsedURL}get/gerar_pdf/vistoria?codAgendamento=${codAgendamento}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          responseType: 'blob',
        }
      );
      if (response.status === 200) {
        // Cria um arquivo blob a partir da resposta
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const url = URL.createObjectURL(blob);
        window.open(url);
      } else {
        console.error('Erro ao gerar o PDF:', response.status);
      }
    } catch (error) {
      console.log(error);
    }
  }
  //GET dados do formulario
  const handleGetAgendamento2 = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/agendamento2?codAgendamento=${codAgendamento}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setFormState(response.data.content);
        setSeletor(response.data.content);
        const { agendamentodata } = response.data.content;
        updateDatahora(agendamentodata)
        const { codStatus } = response.data.content;
        if (codStatus === 2) {
          setStatus(false)
        }
        console.log(seletor.commodities)
        console.log(formState.tipoCarga)
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  //funçao para alteraçao de horario agendado
  const updateDatahora = (value) => {
    if (value) {
      const [dt, horaCompleta] = value.split(' ');
      const [hora, minutos] = horaCompleta.split(':');
      console.log(dt);
      const date = new Date(value);
      console.log(date);
      const data = date.toISOString().slice(0, 10);

      setdatahora({
        data: data,
        hora: `${hora}:${minutos}`
      });
    }
  };
  
  //exibe as margens no seletor
  const handleGetMargens = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/margem`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setMargens(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }
 
  //exibe os patios no seletor
  const handleGetPatios = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/patios`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setPatio(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  //exibe os tipos de carga no seletor (COMMODITIES)
  const handleGetCommo = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/commodities`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setCommo(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleGetCommo2 = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/commoditie?cat=${seletor.commodities}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        console.log(response.data.content);
        setCommo2(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }
 
  //exibe os armadores no seletor
  const handleGetArmador = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/armador`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setArmador(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }
  //exibe os tipos de container no seletor
  const handleGetTipo = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/tipocc`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setTipocc(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }
  //exibe os padroes de container no seletor
  const handleGetCargoType = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/cargo_type`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setCargoType(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }
  //exibe os status de agendamento no seletor
  const handleGetStatusAgend = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/status_agend`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setStatusAgend(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }
  //exibe os destinos no seletor
   const handleGetDest = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/destinos`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setDestino(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  } 
  {/*
  //exibe as veiculos no seletor  
  const handleGetVeiculo = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/veiculos`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setVeiculo(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }  */}
 
  //exibe os Exportadores no seletor    
  const handleGetEnt = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/resp`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setEnti(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleGetEmpVist = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/emp_vist`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setEmpVist(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  //Handle de hora
  const horaChange = (event) => {
    const { name, value } = event.target;
    setdatahora(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  
  //handle de form
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
    setSeletor(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
 
  //handle seleçao de Exportador
  const handleRowDoubleClick = (params) => {
    setFormState(prevState => ({
      ...prevState,
      codResp: params.data.id_entidade,
      cliente: params.data.razao_social
    }));
    setShowConfirmationModal(false);
  };
  //handle para abrir o modal Exportadores
  const handleClickSeach = (tipoBusca) => {
    setSelectType(tipoBusca);
    handleGetEnt();
    setShowConfirmationModal(true);
  };

  //handle seleçao de Destinos
  const handleDoubleClickDest = (params) => {
    setFormState(prevState => ({
      ...prevState,
      codDestino: params.data.codDestino,
      destino: params.data.tabelaAntomar
    }));
    setShowDestinoModal(false);
  };
  //handle para abrir o modal Destinos
  const handleClickSearch = (tipoBusca) => {
    setSelectDest(tipoBusca);
    handleGetDest();
    setShowDestinoModal(true);
  };

  //handle seleçao de Empresa Vistoria
  const handleDoubleClick = (params) => {
    setFormState(prevState => ({
      ...prevState,
      idEmpVist: params.data.id_entidade,
      empVist: params.data.razao_social
    }));
    setShowEmpVistModal(false);
  };
  //handle para abrir o modal Empresa Vistoria
  const handleSearchVist = (tipoBusca) => {
    setSelectType(tipoBusca);
    handleGetEmpVist();
    setShowEmpVistModal(true);
  };
 
 
  //colunas modal exportadores
  const columns = [
    {field: "id_entidade",headerName: "ID",width: 50},
    {field: "razao_social",headerName: "Razão Social",width: 200,filter: true,floatingFilter:true},
    {field: "cnpj_cpf",headerName: "CNPJ",width: 180,filter: true,floatingFilter:true}
  ];

  //colunas modal destinos
  const columnsDest = [
    {field: "codDestino",headerName: "ID",width: 50},
    {field: "tabelaAntomar",headerName: "Porto",width: 200, floatingFilter:true},
    {field: "siglas",headerName: "Sigla",width: 80,filter: true},
  ];
  
  //formata as datas minimas
  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return (`${year}-${month}-${day}`);
  };
  
  //formata as datas maximas
  const parseDateMax = (dateString) => {
    let datav;
    if(permissions.agendamento || permissions.supDoc || permissions.gGeral || permissions.dev || permissions.admin){
      datav = 60;
    }
    else{
      datav = 2;
    }
    const [day, month, year] = dateString.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    date.setDate(date.getDate() + datav);

    const newDay = String(date.getDate()).padStart(2, '0');
    const newMonth = String(date.getMonth() + 1).padStart(2, '0');
    const newYear = date.getFullYear();

    return `${newYear}-${newMonth}-${newDay}`;
  };
  
  //converte as datas
  const today = new Date().toLocaleDateString('pt-BR');
 
  //valida o horario dos agendamentos
  const validateDateTime = (event) => {
    const selectedDateTime = new Date(event.target.value);
    const selectedDT = selectedDateTime;
    selectedDT.setUTCHours(0, 0, 0, 0);
    const now = new Date();
    const currentDate = now;
    currentDate.setUTCHours(0, 0, 0, 0);

    const day = selectedDateTime.getDay();

    if (selectedDT < currentDate) {
      toast.error('Não é possível agendar para uma data passada!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setdatahora(prevState => ({
        ...prevState,
        data: ""
      }));
      return;
    }

    if (day === 6) {
      toast.error('Não é possivel agendamentos aos domingos!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setdatahora(prevState => ({
        ...prevState,
        data: ""
      }));
      return;
    }

    setdatahora(prevState => ({
      ...prevState,
      data: selectedDateTime.toISOString().slice(0, 10)
    }));
    console.log(datahora.data)
  };
 
  //gera os horarios no seletor em horas zeradas (01:00)
  const generateTimeSlots = () => {
    const now = new Date();
    const selectedDate = new Date(datahora.data);
    const dayOfWeek = selectedDate.getDay();
    const startHour = dayOfWeek === 5 ? 7 : 7;
    const endHour = dayOfWeek === 5 ? 13 : 22;
    let timeSlots = [];
    const currentDay = new Date(datahora.data).getUTCDate();
    const today = now.getDate();
    for (let hour = startHour; hour <= endHour; hour++) {
      const slotTime = new Date();
      slotTime.setHours(hour, 0, 0, 0);
      if (currentDay === today) {
        const twoHoursAhead = new Date(now.getTime() + 2 * 60 * 60 * 1000);
        if (slotTime >= twoHoursAhead) {
          const formattedHour = hour.toString().padStart(2, '0');
          timeSlots.push(`${formattedHour}:00`);
        }
      } else {
        const formattedHour = hour.toString().padStart(2, '0');
        timeSlots.push(`${formattedHour}:00`);
      }
    }
    return timeSlots;
  };
  const timeSlots = generateTimeSlots();
  const agend = (`${datahora.data} ${datahora.hora}:00`)
  formState.agendamentodata = agend
  
  //anexar arquivos
  const handleAttachFiles = () => {
    setShowModal(true);
  };
  //Abre o modal de aviso de saldo
  const handleModalConfirm = () => {
    setModalIsOpen(false);
    executeUpdate();
  };
  //Fecha o modal de aviso de saldo
  const handleModalCancel = () => {
    setHandleButtonBlockSave(false);
    setModalIsOpen(false);
  };

  //handle formulario de motoristas
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "cpf"){
      const formattedValue = formatCPF(value);
      setNewMotorista({ ...newMotorista, [name]: formattedValue });
    }
    else{
      const formattedValue2 = formatPlaca(value);
      setNewMotorista({ ...newMotorista, [name]: formattedValue2 });
    }
  };
  //handle que verifica se o CPF ja existe no DB
  const handleCPFBlur = async () => {
    setIds((prevState) => ({
      ...prevState,
      codVeiculo: null,
    }));
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/motorista2?cnpj_cpf=${newMotorista.cpf}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 208) {
        console.log(response.data.content);
        // Preenche automaticamente o formulário se o motorista já existir
        setNewMotorista((prevState) => ({
          ...prevState,
          nome: response.data.content[0].razao_social,
          cnh: response.data.content[0].cnh,
        }));
        console.log(response.data.content[0].id_entidade);
        setIds((prevState) => ({
          ...prevState,
          codMotorista: response.data.content[0].id_entidade
        }));
        setReg(true);
      }
      else if(response.status === 200){
        toast.error('Cadastro não encontrado\n Redirecionando para a pagina de cadastro...', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          window.open('/cadastro_entidade?codCadastro=2', '_blank', 'noopener,noreferrer');
          setshowMotorModal(false);
        }, 3000);
        console.log(response)
      }
      console.log(ids)
    } catch (error) {
      console.log(error)
    }
  };
  //handle que verifica se a placa ja existe no DB
  const handlePlacaBlur = async () => {
    setIds((prevState) => ({
      ...prevState,
      codVeiculo: null,
    }));
    try {
      const response = await axios.get(
        `${parsedURL}get/tabelas/veiculos2?placa=${newMotorista.placa}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (response.status === 208) {
        console.log(response.data.content[0].codVeiculo)
        setIds((prevState) => ({
          ...prevState,
          codVeiculo: response.data.content[0].codVeiculo,
        }));
        console.log(ids.codVeiculo)
      }
    } catch (error) {
      console.log(error)
    }
  };
  {/*
  //Executa o registro de um novo motorista no DB
  const postCPF = async () => {
    let codMoto = null
    if (ids.codMotorista === null) {
      try {
        const postResponse = await axios.post(
          `${parsedURL}post/cadastro/motorista`,
          {
            cnpj_cpf: newMotorista.cpf,
            razao_social: newMotorista.nome,
            cnh: newMotorista.cnh,
            doc_type: 'CPF',
            M: 1
          },
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (postResponse.status === 200) {
          console.log(postResponse);
          codMoto = postResponse.data.codMotorista;
        }
      } catch (postError) {
        console.error("Erro ao cadastrar motorista:", postError);
      }
      return {codMoto}
    };
  }
  const postPlaca = async () => {
    let codVeic = null
    try {    
      const postVeiculo = await axios.post(
          `${parsedURL}post/cadastro/veiculo`,
          {
            placa: newMotorista.placa,
            tipo: 'R',
          },
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (postVeiculo.status === 200) {
          console.log(postVeiculo.data.codVeiculo)
          codVeic = postVeiculo.data.codVeiculo;
        }

      } catch (postError) {
        console.error("Erro ao cadastrar motorista:", postError);
      }
      return {codVeic}
    };
  */}  
  //const handleEnvioMoto = async () => {
  //  let codVeic;
  //  let codMoto;
  //  codMoto = ids.codMotorista;
  //  codVeic = ids.codVeiculo;
  //  handleAddMotorista( codMoto.codMoto, codVeic.codVeic);
  //}

  //Adiciona os motoristas ao array
  const handleAddMotorista = () => {
    console.log(ids)
    console.log(currentIndex)
    const updatedMotoristas = [...arrayData];
    updatedMotoristas[currentIndex] = {
      ...updatedMotoristas[currentIndex],
      cancel: 0,
      codMotorista: ids.codMotorista,
      codVeiculo: ids.codVeiculo,
      nome: newMotorista.nome,
      placa: newMotorista.placa,
    };
    setArrayData(updatedMotoristas)
    console.log(arrayData[currentIndex])
    console.log(updatedMotoristas)
    setReg(false);
    setIds({
      codMotorista: null,
      codVeiculo: null,
    });
    // Fecha o modal e reseta o formulário
    setshowMotorModal(false);
    setNewMotorista({ cpf: "", nome: "", cnh: "", placa: ""});
  };
  //remove um motorista do array
  const handleRemoveMotorista = (index) => {
    console.log(index)
    console.log(arrayData[index].cancel)
    console.log(arrayData)
    setMotoristasTemp((prevMotoristas) => {
      const updatedMotoristas = [...prevMotoristas];
      updatedMotoristas[index].cancel = 1; // Marca como cancelado
      return updatedMotoristas;
    });
  };
  //exibe os motoristas agendados
  const handleGetMotAgend = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/motAgend?codAgendamento=${codAgendamento}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setArrayData(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }
  // Envia os motoristas para o backend após a confirmação do agendamento
  const handleConfirmaMot = async() =>{
    await handlePostMotAgend();
    await handlePutMotAgend();
  }

  const handlePostMotAgend = async () => {
    if (codAgend != [] || !codAgend) {
      try {
        const newData = arrayData.filter(data => !data.codMA);
        //const newJson = newData.map(({ cancel,
        //  codMotorista,
        //  codVeiculo }) => ({
        //    cancel,
        //    codMotorista,
        //    codVeiculo
        //  }))
        console.log(codAgend)
        console.log(arrayData)
        await axios.post(
          `${parsedURL}post/cadastro/motAgend?codAgend=${codAgend}`,
          newData,
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        console.error("Erro ao enviar motoristasAgend:", error);
      }
    }
  };
  // Atualiza os motoristas 
  const handlePutMotAgend = async () => {
    try {
      const newData = arrayData.filter(data => data.codMA);
      //console.log(motoristasTemp)
      //const newJson = newData.map(({ codMA, cancel,
      //   }) => ({
      //    codMA, cancel
      //  }))
      //console.log(newJson)
      await axios.put(
        `${parsedURL}put/cadastro/motAgend`,
        newData,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Erro ao enviar motoristasAgend:", error);
    }
  };
  const [currentIndex, setCurrentIndex] = useState(null);
  const renderAddButton = (params) => {
    console.log(params.rowIndex)
    return (
      <div type="button"className="btn btn-sm btn-primary" onClick={() => {setCurrentIndex(params.rowIndex); setshowMotorModal(true)}}>
        <ADDPerson/>
      </div>
    );
  }
  const renderEraseBtn = (params) => {
    return (
      <Button className="btn-sm btn-danger" onClick>
        <Remove />
      </Button>
    );
  }
  const renderPrintButton = (params) => {
    return (
      <Button className="btn-sm btn-success" disabled={status} onClick={() => gerarPdf(params.data.codMA)}>
        <PrintIcon />
      </Button>
    );
  }

  const handleTestConsole = () => {
    console.log(arrayData)
  }
  
  //ANEXOS E DOWNLOAD
  const handleAppendFile = (event) => {
    const arquivoSelecionados = event.target.files;
    setFiles(Array.from(arquivoSelecionados));
  };
  const [isLoading, setIsLoading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const handleFileUpload = async () => {
    setIsLoading(true); // Ativa o spinner
    for (const file of files) {
      const formData = new FormData();
      formData.append('arquivo',file)
      console.log(file)
      try {
        const response = await axios.post(
          `${parsedURL}post/agendamento/upload_file?book=${formState.booking}`,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        if (response.status === 200)
        {
          console.log(response.data.content)
        }
      } catch (error) {
        toast.error('Erro ao concluir a operação!', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    setIsLoading(false); // Desativa o spinner
    setUploaded(true);
  };
  const handleDownloadZip = async () => {
    try {
      const response = await axios.get(`${parsedURL}get/arquivos/download_files?book=${formState.booking}&codAgend=${codAgendamento}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        responseType: 'blob', 
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${formState.booking}.zip`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error)
    }
  };
  
  //VALIDA OS CAMPOS DO FORMULARIO
  const validateFields = () => {
    const newErrors = {};
    if (!formState.booking) newErrors.booking = "Campo obrigatório";
    if (!formState.codArmador) newErrors.codArmador = "Campo obrigatório";
    if (!formState.quant) newErrors.quant = "Campo obrigatório";
    if (!formState.codResp) newErrors.codResp = "Campo obrigatório";
    if (!datahora.data) newErrors.data = "Campo obrigatório";
    if (!datahora.hora) newErrors.hora = "Campo obrigatório";
    if (!formState.codMargem) newErrors.codMargem = "Campo obrigatório";
    if (!formState.codTipo) newErrors.codTipo = "Campo obrigatório";
    if (!formState.navio) newErrors.navio = "Campo obrigatório";
    {/*if (!formState.viagem) newErrors.viagem = "Campo obrigatório";*/}
    if (!formState.cargotype) newErrors.cargotype = "Campo obrigatório";
    if (!formState.pesoCarga) newErrors.pesoCarga = "Campo obrigatório";
    if (!formState.codDestino) newErrors.codDestino = "Campo obrigatório";
    if (!formState.tipoCarga) newErrors.tipoCarga = "Campo obrigatório";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  //seletor de Commodities 
  useEffect(() => {
    if (seletor.commodities !== "") {
      handleGetCommo2();
    }
  }, [seletor.commodities]);

  //Grid horario retirada
  const [arrayData, setArrayData] = useState([]);
  
  useEffect(() => {
    if (formState.codStatus === 2 || formState.codStatus === '2') {
      if (!arrayData.length){
        updateArrayData();
      }
    }
  },[formState.codStatus]);
  
  const generateHoraSlots = () => {
    const now = new Date();
    const selectedDate = new Date(datahora.data);
    const dayOfWeek = selectedDate.getDay();
    let hora = moment(formState.agendamentodata).format('HH');
    const startHour = hora;
    const endHour = dayOfWeek === 5 ? 13 : 22;
    let timeSlots2 = [];
    const currentDay = new Date(datahora.data).getUTCDate();
    const today = now.getDate();
    for (let hour = startHour; hour <= endHour; hour++) {
      const slotTime = new Date();
      slotTime.setHours(hour, 0, 0, 0);
      if (currentDay === today) {
        const twoHoursAhead = new Date(now.getTime() + 2 * 60 * 60 * 1000);
        if (slotTime >= twoHoursAhead) {
          const formattedHour = hour.toString().padStart(2, '0');
          timeSlots2.push(`${formattedHour}:00`);
        }
      } else {
        const formattedHour = hour.toString().padStart(2, '0');
        timeSlots2.push(`${formattedHour}:00`);
      }
    }
    return timeSlots2;
  };
  const timeSlots2 = generateHoraSlots();

  const updateArrayData = () => {
    const newArrayData = Array.from({ length: formState.quant }, (_, index) => ({
      nome: '',
      placa: '',
      hora: moment(formState.agendamentodata).format('HH:mm'),
      container:'',
      cancel: 0   }));
    setArrayData(newArrayData);
    console.log(arrayData[0])
  };

  const postCommodities = async () => {
    try {
      const response = await axios.post(`${parsedURL}post/tabelas/commodities`,
        {descricao: formState.outros,
        },{
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`
          },
        }
      );
      console.log(response.data.codCom)
      setFormState(prevState => ({
        ...prevState,
        tipoCarga: response.data.codCom,
      }));
      console.log(formState.tipoCarga)
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const formatCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };
  const formatPlaca = (placa) => {
    placa = placa.replace(/[^a-zA-Z0-9]/g, "");
    return placa.replace(/(\w{3})(\d{4})/, "$1-$2");
  }
  
  console.log(seletor)
  return (
    <>
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" 
          role="tab" aria-controls="nav-home" aria-selected="true">Principal</button>
          <button class="nav-link bg-info" id="nav-vistoria-tab" data-bs-toggle="tab" 
          data-bs-target="#nav-vistoria" type="button" role="tab" aria-controls="nav-vistoria" aria-selected="false" disabled={formState.vistoria === 1 || formState.vistoria === '1' ? false : true}>Vistoria</button>
        </div>
      </nav>
      <div class="tab-content mt-3" id="nav-tabContent">
        <div class="tab-pane fade fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
          <form class="row g-3" onSubmit={handleSubmit}>
            <div class="col-md-4">
              <div className="mb-3">
                <label for="booking" class="form-label fw-bold">Booking:</label>
                <input type="text" disabled={readOnly} class="form-control transparent-input" id="booking" name="booking"
                 value={formState.booking} onChange={handleChange} />
                {errors.booking && <span className="text-danger">{errors.booking}</span>}
              </div>
              <div className="mb-3">
                <label for="codArmador" class="form-label fw-bold">Armador:</label>
                <select value={formState.codArmador} onChange={handleChange} disabled={readOnly} name="codArmador" 
                id="inputState" class="form-select transparent-input">
                  <option selected value=''>Selecione...</option>
                  {armador.map((opcao) => (
                    <option key={opcao.id_entidade} value={opcao.id_entidade}>{opcao.fantasia}</option>
                  ))}
                </select>
                {errors.codArmador && <span className="text-danger">{errors.codArmador}</span>}
              </div>
              <div className="mb-3">
                <label class="hfx form-label fw-bold">Exportador:</label>
                <div class="input-group">
                  <input type="text" value={formState.cliente} disabled={readOnly} readOnly={true} 
                  class="form-control transparent-input" />
                  <button type="button" disabled={readOnly} onClick={() => handleClickSeach("codResp")} 
                  class="input-group-text btn-primary btn"><Search /></button>
                </div>
                {errors.codResp && <span className="text-danger">{errors.codResp}</span>}
              </div>
              <div className="mb-3">
                <label for="tipoCarga" className="form-label  fw-bold ">Categoria da Carga:</label>
                <select value={seletor.commodities} onChange={handleChange} disabled={readOnly} name="commodities" 
                id="inputState" class="form-select transparent-input">
                  <option selected value=''>Selecione...</option>
                  {commodities.map((opcao) => (
                    <option key={opcao.idcommodities} value={opcao.idcommodities}>{opcao.descricao}</option>
                  ))}
                </select>
                {(seletor.commodities !== "") &&
                  (<div className="row align-items-center mt-1">
                    <div className="col-auto mt-2">
                      <span className="ms-3"><Larrow/></span>
                    </div>
                    <div className="col">
                      {(seletor.commodities !== "58") && (
                      <select value={formState.tipoCarga} onChange={handleChange} disabled={readOnly} name="tipoCarga"
                        id="inputState" className="form-select transparent-input mt-2">
                        <option selected value=''>Selecione...</option>
                        {commodities2.map((opcao) => (
                          <option key={opcao.idcommodities} value={opcao.idcommodities}>{opcao.descricao}</option>
                        ))}
                      </select>)}
                      {(seletor.commodities ==="58") && (<input type="text" 
                        disabled={readOnly} class="form-control transparent-input mt-2" id="outros" placeholder="Digite..." 
                        name="outros" value={formState.outros} onChange={handleChange} />)}
                    </div>
                  </div>)}
                {/*<input type="text" disabled={readOnly} class="form-control transparent-input" id="tipoCarga" 
                name="tipoCarga" value={formState.tipoCarga} onChange={handleChange} />*/}
                {errors.tipoCarga && <span className="text-danger">{errors.tipoCarga}</span>}
              </div>
              <div className="row">
                <div class="col mb-3">
                  <label htmlfor="genset" class="form-label fw-bold">Genset:</label>
                  <select value={formState.genset} disabled={readOnly} onChange={handleChange} name="genset" 
                  id="inputState" class="form-select transparent-input">
                    <option selected value="0">Selecione...</option>
                    <option value="1">Sim</option>
                  </select>
                </div>
                {formState.genset === '1' && (<>
                  <div class="col-md-6">
                    <label for="pesoliq" class="form-label fw-bold">Litragem:</label>
                    <input type="text" disabled={readOnly} class="form-control transparent-input" id="pesoliq" 
                    name="pesoliq" value={formState.pesoliq} onChange={handleChange} />
                  </div>
                </>)}
              </div>
            </div>
            <div class="col-md-4">
              <div className="mb-3">
                <label for="codTipo" class="form-label fw-bold">Tipo de Container:</label>
                <select value={formState.codTipo} disabled={readOnly} onChange={handleChange} name="codTipo" 
                id="inputState" class="form-select transparent-input">
                  <option selected value=''>Selecione o tipo...</option>
                  {tipocc.map((opcao) => (
                    <option key={opcao.codTipo} value={opcao.codTipo}>{opcao.descricao}</option>
                  ))}
                </select>
                {errors.codTipo && <span className="text-danger">{errors.codTipo}</span>}
              </div>
              <div className="row mb-3">
                <div className="col">
                  <label for="cargotype" class="form-label fw-bold">Padrão:</label>
                  <select value={formState.cargotype} disabled={readOnly} onChange={handleChange} name="cargotype" 
                  id="inputState" class="form-select transparent-input">
                    <option selected value=''>Selecione o padrão...</option>
                    {cargo_type.map((opcao) => (
                      <option key={opcao.id_type} value={opcao.id_type}>{opcao.description}</option>
                    ))}
                  </select>
                  {errors.cargotype && <span className="text-danger">{errors.cargotype}</span>}
                </div>

                { formState.cargotype === '2' || formState.cargotype === 2  ?
                  <>
                  <div class="col-md-4">
                    <label for="vistoria" class="form-label fw-bold">Vistoria:</label>
                    <select value={formState.vistoria} disabled={readOnly} onChange={handleChange} name="vistoria" 
                    id="inputState" class="form-select transparent-input">
                      <option selected value="0">Selecione...</option>
                      <option value="1">Sim</option>
                    </select>
                  </div>
                  </> : 
                  <></>
                  }

              </div>
              <div className="mb-3">
                <label for="pesoCarga" class="form-label fw-bold">Peso Carga (MGW):</label>
                <select value={formState.pesoCarga} disabled={readOnly} onChange={handleChange} name="pesoCarga" 
                id="pesoCarga" class="form-select transparent-input">
                  <option selected>Selecione...</option>
                  {(() => {
                    switch (formState.codTipo) {
                      case '1':
                      case '3':
                      case '5':
                      case '6':
                      case '7':
                      case '9':
                      case '10':
                      case '13':
                      case '14':
                        return (
                          <>
                            <option>30480</option>
                            <option>32500</option>
                            <option>32000</option>
                          </>
                        );
                      case '12':
                        return (
                          <>
                            <option>30480</option>
                            <option>32500</option>
                            <option>34000</option>
                          </>
                        );
                      case '2':
                        return (
                          <>
                            <option>34000</option>
                          </>
                        );
                      case '4':
                        return (
                          <>
                            <option>27000</option>
                            <option>30480</option>
                            <option>32000</option>
                            <option>32500</option>
                          </>
                        );
                      case '8': case'11':
                        return (
                          <>
                            <option>45000</option>
                            <option>50000</option>
                            <option>52500</option>
                            <option>55000</option>
                          </>
                        );

                      default:
                        return null;
                    }
                  })()}
                </select>
                {errors.pesoCarga && <span className="text-danger">{errors.pesoCarga}</span>}
              </div>
              <div className="row mb-3">
                <div className="col-md-8">
                  <label for="data" className="form-label fw-bold">Data p/ Retirada:</label>
                  <input type="date" disabled={readOnly} className="form-control transparent-input" id="data" 
                  name="data" min={parseDate(today)} max={parseDateMax(today)} value={datahora.data} onChange={validateDateTime}/>
                  {errors.data && <span className="text-danger">{errors.data}</span>}
                </div>
                <div className="col-md-4">
                  <label for="hora" className="form-label fw-bold">Horário:</label>
                  <select className="form-control transparent-input" disabled={readOnly} id="hora" name="hora" 
                  value={datahora.hora} onChange={horaChange}>
                    <option selected value=''>--:--</option>
                    {timeSlots.map(time => (
                      <option key={time} value={time}>{time}</option>
                    ))}
                  </select>
                  {errors.hora && <span className="text-danger">{errors.hora}</span>}
                </div>
                  <span className="mt-3 text-danger">*Data e hora sujeito a alteração.</span>
              </div>
              {(formState.codTipo === '4' || formState.codTipo === '12') && (<>
                <div className="row mb-3">
                  <div class="col-md-6">
                    <label for="temp" class="form-label fw-bold">Temp.:</label>
                    <input type="text" disabled={readOnly} class="form-control transparent-input" id="temp" 
                    name="temp" value={formState.temp} onChange={handleChange} />
                  </div>
                  <div class="col-md-6">
                    <label for="ventilacao" class="form-label fw-bold">Ventilação:</label>
                    <input type="text" disabled={readOnly} class="form-control transparent-input" id="ventilacao" 
                    name="ventilacao" value={formState.ventilacao} onChange={handleChange} />
                  </div>
                </div>
                <div className="row mb-3">
                  <div class="col-md-6">
                    <label for="umidade" class="form-label fw-bold">Umidade:</label>
                    <input type="text" disabled={readOnly} class="form-control transparent-input" id="umidade" 
                    name="umidade" value={formState.umidade} onChange={handleChange} />
                  </div>
                  <div class="col-md-6">
                    <label for="probes" class="form-label fw-bold">Probes:</label>
                    <input type="text" disabled={readOnly} class="form-control transparent-input" id="probes" 
                    name="probes" value={formState.probes} onChange={handleChange} />
                  </div>
                </div>
              </>)}
            </div>
            <div className="col-md-4">
              <div class="mb-3">
                <label for="quant" class="form-label fw-bold">Qtd:</label>
                <input type="number" disabled={readOnly} class="form-control transparent-input" id="quant" min="0" 
                name="quant" value={formState.quant} onChange={handleChange} />
                {errors.quant && <span className="text-danger">{errors.quant}</span>}
              </div>
              <div class="mb-3">
                <label for="navio" class="form-label fw-bold">Navio:</label>
                <input type="text" disabled={readOnly} class="form-control transparent-input" id="navio" name="navio" 
                value={formState.navio} onChange={handleChange} />
                {errors.navio && <span className="text-danger">{errors.navio}</span>}
              </div>
              {/*<div class="mb-3">
                <label for="viagem" class="form-label fw-bold">Viagem:</label>
                <input type="text" disabled={readOnly} class="form-control transparent-input" id="viagem" name="viagem" 
                value={formState.viagem} onChange={handleChange} />
                {errors.viagem && <span className="text-danger">{errors.viagem}</span>}
              </div>*/}
              <div class="mb-3">
                <label for="codDestino" class="form-label fw-bold">Destino:</label>
                <div className="input-group">
                  {/*<input type="text" disabled={readOnly} class="form-control transparent-input" id="codDestino" 
                  name="codDestino" value={formState.destino} onChange={handleChange} /> */}
                  <input type="text" value={formState.destino} disabled={readOnly} readOnly={true} 
                  class="form-control transparent-input" />
                  <button type="button" disabled={readOnly} onClick={() => handleClickSearch("codDestino")} 
                    class="input-group-text btn-primary btn"><Search /></button>
                </div>
                {errors.codDestino && <span className="text-danger">{errors.codDestino}</span>}
              </div>
              <div class="mb-3">
                <label for="inputState" class="form-label fw-bold">Área:</label>
                <select value={formState.codMargem} disabled={readOnly} onChange={handleChange} name="codMargem" 
                id="inputState" class="form-select transparent-input">
                  <option selected value=''>Selecione a Area</option>
                  {margens.map((opcao) => (
                    <option key={opcao.codArea} value={opcao.codArea}>{opcao.descricao}</option>
                  ))}
                </select>
                {errors.codMargem && <span className="text-danger">{errors.codMargem}</span>}
              </div>
            </div>
            {(formState.codStatus === 1 || formState.codStatus === '1' || formState.codStatus === '') &&(<div>
              <div className="bg-warning p-1"><h4 className="text-center mt-5 mb-5">Os motoristas apenas poderão ser adicionados após a aprovação do agendamento.</h4></div>
            </div>)}
            {(formState.codStatus === 2 || formState.codStatus === '2') && (<div class="row mt-3 col-12">
              <h5 className="fw-bold mb-3">Horarios para Retirada:</h5>
              <div className="row d-flex mb-3 ms-0 justify-content-between">
                <div type="button" className="btn btn-success col-3" onClick={handleConfirmaMot}>
                  Confirmar
                </div>
              </div>
                {/*{ headerName: "Cancel", field: "remove",cellRenderer: (params) => renderButton(params), width: 80 },*/}
              <div className="ag-theme-quartz" style={{ height: 300 }}>
                <AgGridReact
                  rowData={arrayData}
                  columnDefs={[
                    { headerName: "Nome", field: "nome",cellRenderer: (params) => {if (params.value === "" || params.value === null) {
                      return renderAddButton(params);
                    } else {
                      return params.value;
                    }}},
                    { headerName: "Placa", field: "placa", width: 100 },
                    { headerName: 'Horario', field:'hora', width: 100,
                      cellEditor: 'agSelectCellEditor',
                      cellEditorParams: {
                        values: timeSlots2.map((time) => time) 
                      },
                      editable: true
                    },
                    { headerName: "PDF", field: "print", cellRenderer: (params) => renderPrintButton(params, params.data.codMA), width: 80 },
                    { headerName: "Container", field: "container", width: 150 },
                    {
                      headerName: "Status", field: "cancel", width: 100, cellRenderer: (params) =>
                        params.data.cancel === 1 ? (<span style={{ color: "red" }}>Cancelado</span>) : ("Ativo")
                    },
                  ]}
                  getRowStyle={(params) => {
                    if (params.data.cancel === 1) {
                      return { backgroundColor: "#f8d7da" }; // Linha desativada em vermelho claro
                    }
                    return {};
                  }}
                />
              </div>
            </div>
            )}
            {(permissions.admin || permissions.dev) && (<div class="row align-items-end  p-3">
              <div class="col-md-3">
                <label class="form-label fw-bold">Status do Agendamento:</label>
                <select className="form-control transparent-input" id="inputState" name="codStatus" value={formState.codStatus} 
                onChange={handleChange}>
                  <option selected value=''></option>
                  {statusagend.map(opcao => (
                    <option key={opcao.codStatus} value={opcao.codStatus}>{opcao.descricao}</option>
                  ))}
                </select>
              </div>
              <div class="col-md-3">
                <label class="form-label fw-bold">Patio:</label>
                <select className="form-control transparent-input" id="inputState" name="idPatio" value={formState.idPatio} 
                onChange={handleChange}>
                  <option selected value='0'>Selecione...</option>
                  {patio.map(opcao => (
                    <option key={opcao.codPatio} value={opcao.codPatio}>{opcao.descricao}</option>
                  ))}
                </select>
              </div>
            </div>)}
            <div class="col-md-12">
              <label for="obs" class="form-label fw-bold">Observações:</label>
              <textarea class="form-control transparent-input" rows="5" id="obs" name="obs" value={formState.obs} 
              onChange={handleChange}></textarea>
            </div>
            <div class="col-md-12">
              <button type="button" class="btn btn-success" disabled={handleButtonBlockSave} onClick={handleCheck}>Salvar</button>
              <button type="button" class="btn btn-info m-2" disabled={status} onClick={gerarPdfLote}>Gerar minutas em PDF</button>
              {(status === false && (formState.vistoria === 1 || formState.vistoria === '1')) && 
              (<button type="button" class="btn btn-info m-2" disabled={false} onClick={gerarPdfVistoria}>Guia de Vistoria</button>)}
              {(!permissions.cliente) && (<button type="button" class="btn btn-warning float-end mt-2" onClick={handleDownloadZip}>Baixar Arquivos</button>)}
              {(permissions.cliente) && (<button type="button" class="btn btn-primary float-end mt-2" onClick={handleAttachFiles}>Anexar Arquivos</button>)}
            </div>
          </form>
        </div>

        <div class="tab-pane fade" id="nav-vistoria" role="tabpanel" aria-labelledby="nav-vistoria-tab">
          <h4 class="mb-4">Dados do Vistoriador</h4>
          <div class="col-md-4 mb-3">
            <label for="cpfVist" class="form-label fw-bold">CPF:</label>
            <div className="input-group">
              <input type="text" class="form-control transparent-input" id="cpfVist" name="cpfVist" value={formState.cpfVist}
                onChange={handleChange} maxLength={11} required/>
              <button type="button" onClick={() => vistBlur()}
                class="input-group-text btn-success btn">Validar</button>
            </div>
          </div>
          <div class="col-md-4 mb-4">
            <label for="nomeVist" class="form-label fw-bold">Nome do Vistoriador:</label>
            <input type="text" class="form-control transparent-input" id="nomeVist" name="nomeVist" disabled 
            value={formState.nomeVist} 
            onChange={handleChange} />
          </div>
          <div class="col-md-4 mb-4">
            <label for="empVist" class="form-label fw-bold"> Empresa de Vistoria:</label>
            <div class="input-group">
                  <input type="text" value={formState.empVist} disabled={readOnly} readOnly={true} 
                  class="form-control transparent-input" />
                  <button type="button" disabled={readOnly} onClick={() => handleSearchVist("idEmpVist")} 
                  class="input-group-text btn-primary btn"><Search /></button>
            </div>
            {/*<input type="text" class="form-control transparent-input" id="empVist" name="empVist" value={formState.empVist} onChange={handleChange}/>*/}
          </div>
          {(permissions.admin || permissions.dev) && (<div class="col-md-4 mb-3">
            <h4 className="mb-3">Aprovar:</h4>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="vistOption" id="vistOption1" value="1" 
              checked={formState.vistOption === '1'} onChange={handleChange} />
              <label class="form-check-label" for="vistOption1">Somente Vistoria</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="vistOption" id="vistOption2" value="2" 
              checked={formState.vistOption === '2'} onChange={handleChange} />
              <label class="form-check-label" for="vistOption2">Vistoria e Retirada</label>
            </div>
          </div>)}
        </div>
      </div>
      {showMotorModal && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-sm" role="document">
            <div className="modal-content">
              <div className="modal-header bg-warning">
                <h5 className="modal-title mx-auto fw-bold">Adicionar Motorista</h5>
                <button type="button" class="btn-close" onClick={() => setshowMotorModal(false)} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="cpf" class="fw-bold">CPF:</label>
                  <div className="input-group">
                    <input type="text" className="form-control" id="cpf" name="cpf" value={newMotorista.cpf} 
                    onChange={handleInputChange} required maxLength={11} pattern="[0-9]*" 
                    inputMode="numeric" onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}/>
                    <button type="button" onClick={() => handleCPFBlur()} 
                    class="input-group-text btn-primary btn"><Search /></button>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="placa" class="fw-bold">Placa do Veículo:</label>
                  <input type="text" className="form-control" id="placa" name="placa" maxLength={7} value={newMotorista.placa} 
                  onChange={handleInputChange} onBlur={handlePlacaBlur} required />
                </div>
                <div className="form-group">
                  <label htmlFor="nome" class="fw-bold">Nome:</label>
                  <input type="text" className="form-control" id="nome" name="nome" disabled
                  /*={reg ? true : novoSaldo === 0 ? true : false}*/ value={newMotorista.nome} required />
                </div>
                {/*<div className="form-group">
                  <label htmlFor="cnh" class="fw-bold">CNH:</label>
                  <input type="text" className="form-control" id="cnh" name="cnh" value={newMotorista.cnh}  
                  onChange={handleInputChange} required disabled/>
                </div>*/}
                {/*<div className="form-group">
                  <label htmlFor="quant" class="fw-bold">Quantidade:</label>
                  <input type="number" className="form-control" id="quant" name="quant" max={novoSaldo} 
                  value={newMotorista.quant} disabled={novoSaldo === 0 ? true : false}
                  onChange={handleInputChange} min={1} />
                </div>*/}
              </div>
              <div className="modal-footer mx-auto">
                <button type="button" className="btn btn-success" onClick={handleAddMotorista} disabled={novoSaldo === 0 ? true : false}>
                  Adicionar
                </button>
                {/*<button type="button" className="btn btn-info" onClick={() => setshowMotorModal(false)}>
                  Novo Cadastro 
                </button>*/}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={`modal ${showConfirmationModal ? 'd-block' : ''}`} tabIndex="-1" role="dialog" 
      style={{ display: showConfirmationModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-md modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-warning">
              <h5 className="modal-title"><b>Selecione o Cliente</b></h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowConfirmationModal(false)}></button>
            </div>
            <div className="modal-body ">
              <div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
                <AgGridReact
                  rowData={entidad}
                  columnDefs={columns}
                  rowSelection="single"
                  onRowDoubleClicked={handleRowDoubleClick}
                />
              </div>
            </div>
            <div className="modal-footer ">
              <Link to={`/cadastro_entidade?codCadastro=1`} type="button" className="btn btn-info" 
              onClick={() => setShowConfirmationModal(false)} target="_blank" rel="noopener noreferrer">Cadastrar Novo</Link>
              <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmationModal(false)}>Fechar</button>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal ${showDestinoModal ? 'd-block' : ''}`} tabIndex="-1" role="dialog" 
      style={{ display: showDestinoModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-md modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-warning">
              <h5 className="modal-title"><b>Selecione o Destino</b></h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowDestinoModal(false)}></button>
            </div>
            <div className="modal-body ">
              <div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
                <AgGridReact
                  rowData={destino}
                  columnDefs={columnsDest}
                  rowSelection="single"
                  onRowDoubleClicked={handleDoubleClickDest}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal ${showEmpVistModal ? 'd-block' : ''}`} tabIndex="-1" role="dialog" 
      style={{ display: showEmpVistModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-md modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-warning">
              <h5 className="modal-title"><b>Selecione a Empresa</b></h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowEmpVistModal(false)}></button>
            </div>
            <div className="modal-body ">
              <div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
                <AgGridReact
                  rowData={empVisto}
                  columnDefs={columns}
                  rowSelection="single"
                  onRowDoubleClicked={handleDoubleClick}
                />
              </div>
            </div>
            <div className="modal-footer ">
              <Link to={`/cadastro_entidade?codCadastro=3`} type="button" className="btn btn-info" 
              onClick={() => setShowEmpVistModal(false)} target="_blank" rel="noopener noreferrer">Cadastrar Novo</Link>
              <button type="button" className="btn btn-secondary" onClick={() => setShowEmpVistModal(false)}>Fechar</button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal show={howModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Anexar Arquivos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formFileMultiple" className="mb-3">
              <Form.Label>Selecione os arquivos:</Form.Label>
              <Form.Control type="file" id='file' onChange={handleAppendFile} multiple />
            </Form.Group>
            {isLoading && (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Carregando...</span>
                </Spinner>
              </div>
            )}
            {uploaded && (
              <div className="d-flex justify-content-center">
                <div class="alert alert-success d-flex align-items-center" role="alert">
                  <CheckBoxIcon className="text-success"/>
                  <div>
                    Arquivos enviados com sucesso!
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleFileUpload}>
              Enviar
            </Button>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancelar
            </Button>
          </Modal.Footer>
        </Modal>
        {(modalIsOpen) && (<div isOpen={true} onRequestClose={handleModalCancel} contentLabel="Confirmação de Saldo Baixo">
          <div className="modal show d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-warning"><b className="mx-auto">ATENÇÃO</b></div>
                <div className="modal-body">
                  <p>{modalMessage}</p>
                </div>
                <div className="modal-footer mx-auto">
                  <div type="button" className="btn btn-success" onClick={handleModalConfirm}>OK</div>
                  <div type="button" className="btn btn-danger" onClick={handleModalCancel}>Cancelar</div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

    </>

  );
};

export default CadAgendamento;

