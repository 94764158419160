import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { Checkbox, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from "../navbar/navbar";
import "../../style/style.css";
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Search from "@mui/icons-material/Search.js";
import EntidadeModal from "./entidade_modal"
import CadEntidade from "./cad_entidade";
import Refresh from '@mui/icons-material/Autorenew';

const EntConsulta = () => {
  const [rowData, setRowData] = useState();
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id_entidade = searchParams.get('id_ent');

  const [formState, setFormState] = useState({
    A: 0,
    C: 0,
    T: 0,
    M: 0,
    F: 0,
    V: 0
  });

  useEffect(() => {
    handleGetEntidade();
  }, []);

  const handleGetEntidade = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/entidade`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data.content);
        setRowData(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleGetEntidade2 = async () => {
    try {
      const response = await axios.post(
        `${parsedURL}post/cadastro/entidade2`, formState,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data.content);
        setRowData(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (id_entidade !== null) {
      const modalElement = document.getElementById('modal2');
      modalElement.classList.add('show');
      modalElement.style.display = 'block';
      modalElement.setAttribute('aria-modal', 'true');
      modalElement.setAttribute('role', 'dialog');
      document.body.classList.add('modal-open');
      const backdropElement = document.createElement('div');
      backdropElement.classList.add('modal-backdrop', 'fade', 'show');
      document.body.appendChild(backdropElement);
    }
  }, [id_entidade]);

  const colDefs = [
    { field: "razao_social", headerName: "Razão social", filter: true, width: 250 },
    { field: "fantasia", headerName: "Fantasia", filter: true, width: 250 },
    { field: "cnpj_cpf", headerName: "CPF/CNPJ", filter: true, width: 100 },
    { field: "cidade", headerName: "Cidade", filter: true, width: 100 },
    { field: "estado", headerName: "UF", filter: true, width: 100 },
    { field: "logradouro", headerName: "Endereço", filter: true, width: 100 },
    { field: "numero", headerName: "Nº", filter: true, width: 100 },
    { field: "cep", headerName: "CEP", filter: true, width: 100 },
    //{ field: "telefone", headerName: "Tel", filter: true, width: 100 },
    //{ field: "email", headerName: "E-mail", filter: true, width: 100 },
    {
      field: "id_entidade", headerName: "Detalhes", filter: true, width: 120, cellRenderer: (params) => (
        <Link type="button" to={`/consulta_entidade?id_ent=${params.data.id_entidade}`}
        className="btn mx-2 btn-primary"
        ><Search/></Link>)
    }
  ];

  const handleCheckboxChange = (event) => {
    const { id, value } = event.target;
    setFormState((prevState) => ({
      A: id === 'A' ? '1' : '0',
      C: id === 'C' ? '1' : '0',
      F: id === 'F' ? '1' : '0',
      T: id === 'T' ? '1' : '0',
      M: id === 'M' ? '1' : '0',
      V: id === 'V' ? '1' : '0',
    }));
  };

  return (
    <>
      <Navbar></Navbar>
      <div class="row align-items-start g-1">
        <div class="col-12">
          <div className="box-center">
            <div className="card col-md-12 mx-auto">
              <div className="card-body pt-2">
                <form class="row g-3 py-2">
                  <legend className="form-label p-2">Consulta de Cadastros</legend>
                  <div className="d-flex">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" value={formState.A} name="formType" id="A"  
                      onChange={handleCheckboxChange}></input>
                      <label class="form-check-label" for="flexCheckDefault">Armador</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" value={formState.C} name="formType" id="C" 
                      onChange={handleCheckboxChange}></input>
                      <label class="form-check-label" for="flexCheckDefault">Cliente</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" value={formState.F} name="formType" id="F" 
                      onChange={handleCheckboxChange}></input>
                      <label class="form-check-label" for="flexCheckDefault">Fornecedor</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" value={formState.T} name="formType" id="T" 
                      onChange={handleCheckboxChange}></input>
                      <label class="form-check-label" for="flexCheckDefault">Transportadora</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" value={formState.M} name="formType" id="M" 
                      onChange={handleCheckboxChange}></input>
                      <label class="form-check-label" for="flexCheckDefault">Motorista</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" value={formState.V} name="formType" id="V" 
                      onChange={handleCheckboxChange}></input>
                      <label class="form-check-label" for="flexCheckDefault">Vistoria</label>
                    </div>
                    <div type="button" className="ms-1 btn btn-primary btn-sm" onClick={handleGetEntidade2}><Refresh/></div>
                  </div>
                  <div className="box-center1">
                    <div className=" rounded card">
                      <div className="card col-md-12 mx-auto">
                        <div className="ag-theme-quartz" style={{ height: 600 }}>
                          <AgGridReact
                            rowData={rowData}
                            columnDefs={colDefs}
                            rowSelection="unique"
                            pagination={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form >
              </div>
            </div>
          </div>
        </div>
      </div>
      <EntidadeModal/>
    </>
  );
};

export default EntConsulta;