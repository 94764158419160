import React, { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [permissions, setPermissions] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const verificaUsers = async () => {
      const typeUser = parseInt(localStorage.getItem("typeUser"));
      const userTypes = {
        125: { admin: true },
        150: { gGeral: true },
        200: { cliente: true },
        300: { gate: true },
        320: { documental: true },
        340: { agendamento: true },
        350: { supDoc: true },
        400: { avaria: true },
        450: { supAva: true },
        500: { vistoria: true},
        520: { conferente: true },
        540: { oficina: true },
        550: { gerencPatio: true },
        1616: { dev: true },
      };
      const userTypeConfig = userTypes[typeUser] || {};
      setPermissions(userTypeConfig);
      setLoaded(true);
    };
    verificaUsers();
  }, []);

  return (
    <AuthContext.Provider value={{ permissions, loaded }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

